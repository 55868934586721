import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./image-text-block.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";

import imageTextBlockStock from "../../../../assets/images/component-list/I/image-text-block-stock.jpg";

const ImageTextBlock = ()=>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [imageTextBlockBackgroundColor,setImageTextBlockBackgroundColor]=useState("#000");

    const  onImageTextBlockBackgroundColorChange = (e) =>{
        setTimeout(()=>{
            setImageTextBlockBackgroundColor(e.target.value);
        },1000);
    };

    const [imageTextBlockTextColor,setImageTextBlockTextColor]=useState("#fff");

    const  onImageTextBlockTextColorChange = (e) =>{
        setTimeout(()=>{
            setImageTextBlockTextColor(e.target.value);
        },1000);
    };

    const [imageTextBlockTextFont,setImageTextBlockTextFont]=useState("Sans-Serif");

    const onImageTextBlockFontChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setImageTextBlockTextFont("Arial Narrow");
                return;
            case "Arial Round":
                setImageTextBlockTextFont("Arial Round");
                return;
            case "Calibri":
                setImageTextBlockTextFont("Calibri");
                return;
            case "Cambria":
                setImageTextBlockTextFont("Cambria");
                return;
            case "Courier":
                setImageTextBlockTextFont("Courier");
                return;
            case "Franklin Gothic Medium":
                setImageTextBlockTextFont("Franklin Gothic Medium");
                return;
            case "Garamond":
                setImageTextBlockTextFont("Garamond");
                return;
            case "Geneva":
                setImageTextBlockTextFont("Geneva");
                return;
            case "Georgia":
                setImageTextBlockTextFont("Georgia");
                return;
            case "Gill Sans":
                setImageTextBlockTextFont("Gill Sans");
                return;
            case "Impact":
                setImageTextBlockTextFont("Impact");
                return;
            case "Lucida Sans":
                setImageTextBlockTextFont("Lucida Sans");
                return;
            case "Sans Serif":
                setImageTextBlockTextFont("sans-serif");
                return;
            case "Segoe UI":
                setImageTextBlockTextFont("Segoe UI");
                return;
            case "Times New Roman":
                setImageTextBlockTextFont("Times New Roman");
                return;
            case "Trebuchet MS":
                setImageTextBlockTextFont("Trebuchet MS");
                return;
            case "Verdana":
                setImageTextBlockTextFont("Verdana");
                return;
            default:
                return;    
        }
    }

    const [imageTextBlockTitle,setImageTextBlockTitle]=useState("Nature");

    const onImageTextBlockTitleChange = (e) =>{
        setImageTextBlockTitle(e.target.value);
    }

    const [imageTextBlockText,setImageTextBlockText]=useState("What a beautiful forest");

    const onImageTextBlockTextChange = (e) =>{
        setImageTextBlockText(e.target.value);
    }

    const [tabKey,setTabKey] = useState("jsx");

    const [jsx, setJsx] = useState("");
    const [css, setCss] = useState("");

    useEffect(()=>{
        setJsx(`import React from "react";

import "./image-text-block.css";
import imageTextBlockStock from "../../../../assets/images/component-list/I/image-text-block-stock.jpg";

const ImageTextBlock = () =>{
    return(
        <div class="image-text-block-container">
            <img src={imageTextBlockStock} alt="forest" className="image-text-block-stock"/>
            <div className="image-text-block-text-block">
                <p className="image-text-block-title">${imageTextBlockTitle}</p>
                <p>${imageTextBlockText}</p>
            </div>
        </div>
    );
}

export default ImageTextBlock;`);
    },[imageTextBlockTitle, imageTextBlockText]);

    useEffect(()=>{
        setCss(`.image-text-block-container{
    position:relative;
    font-family: ${imageTextBlockTextFont};
}

.image-text-block-stock{
    width: 100%;
}

.image-text-block-text-block{
    position: absolute;
    bottom: 1.25rem;
    right: 1.25rem;
    background-color: ${imageTextBlockBackgroundColor};
    color: ${imageTextBlockTextColor};
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.image-text-block-title{
    display: block;
    font-size: 1rem;
    margin-top: 1.33rem;
    margin-bottom: 1.33rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    font-family:${imageTextBlockTextFont};
}

.image-text-block-text{
    font-family: ${imageTextBlockTextFont};
}`);
    },[imageTextBlockBackgroundColor,imageTextBlockTextColor,imageTextBlockTextFont]);

    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            <p className="title-text">IMAGE TEXT BLOCK GENERATOR</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="accordian-simple-preview-pane">
                            <div className="accordian-simple-inner-container">
                                <div class="image-text-block-container">
                                    <img src={imageTextBlockStock} alt="forest" className="image-text-block-stock"/>
                                    <div style={{backgroundColor:imageTextBlockBackgroundColor,color:imageTextBlockTextColor}} className="image-text-block-text-block">
                                        <p style={{fontFamily:imageTextBlockTextFont}} className="image-text-block-title">{imageTextBlockTitle}</p>
                                        <p className="image-text-block-text" style={{fontFamily:imageTextBlockTextFont}}>{imageTextBlockText}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Form className="editor-container border border-info">
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Background Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onImageTextBlockBackgroundColorChange(e)}}
                                    defaultValue={imageTextBlockBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Text Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onImageTextBlockTextColorChange(e)}}
                                    defaultValue={imageTextBlockTextColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="1">
                            Font:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                    onImageTextBlockFontChange(e);
                                }} as="select">
                                <option>Arial Narrow</option>
                                <option>Arial Round</option>
                                <option>Calibri</option>
                                <option>Cambria</option>
                                <option>Courier</option>
                                <option>Franklin Gothic Medium</option>
                                <option>Garamond</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Gill Sans</option>
                                <option>Impact</option>
                                <option>Lucida Sans</option>
                                <option>Sans Serif</option>
                                <option>Segoe UI</option>
                                <option>Times New Roman</option>
                                <option>Trebuchet MS</option>
                                <option>Verdana</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Title Text:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <Form.Control defaultValue={imageTextBlockTitle} onChange={(e)=>{
                                    onImageTextBlockTitleChange(e);
                                }}/>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="1">
                            Text:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <Form.Control defaultValue={imageTextBlockText} onChange={(e)=>{
                                    onImageTextBlockTextChange(e);
                                }}/>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                </Form>
                <Row>
                    <Col>
                        <div  className="editor-container border border-info">
                            <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                                <Tab eventKey="jsx" title="JSX">
                                    <ControlledEditor
                                        key="jsx"
                                        value={jsx}
                                        options={{
                                            lineWrapping:true,
                                            lint:true,
                                            lineNumbers:true,
                                            theme:"dracula",
                                            mode:"jsx",
                                            readOnly:true
                                        }}
                                    /> 
                                </Tab>
                                <Tab eventKey="css" title="CSS">
                                    <ControlledEditor
                                    key="css"
                                    value={css}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"css",
                                        readOnly:true
                                    }}
                                />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border border-info how-to-container">
                            <span className="how-to-title">How to Use</span>
                            <ol className="">
                                <li>
                                    Create a jsx file called <strong>image-text-block.jsx</strong> and a css file called <strong>image-text-block.css</strong> in your react-app.
                                </li>
                                <li>
                                    Copy and paste the code above in to a jsx file and a css file.
                                </li>
                                <li>
                                    Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                                </li>
                                <li>
                                    Add the line: import ImageTextBlock from "./image-text-block.jsx"; to App.js or any jsx file
                                </li>
                                <li>
                                    Import {"<ImageTextBlock/>"} in to your App.js file
                                </li>
                                <li>
                                    Run your react app
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ImageTextBlock;