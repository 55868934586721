import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./404-page-party-design.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";

import partyDesign404PageLogo from "../../../../assets/images/component-list/#/404-page-party-design.svg";

const PartyDesign404Page = () =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [partyDesign404PageBackgroundColor,setPartyDesign404PageBackgroundColor] = useState("#FFFFFF");

    const  onPartyDesign404PageBackgroundColorChange = (e) =>{
        setTimeout(()=>{
            setPartyDesign404PageBackgroundColor(e.target.value);
        },1000);
    };

    const [partyDesign404PageText,setPartyDesign404PageText] = useState("Page Not Found");

    const  onPartyDesign404PageTextChange = (e) =>{
        setPartyDesign404PageText(e.target.value);
    }

    const [partyDesign404PageTextColor,setPartyDesign404PageTextColor] = useState("#3b3a39");

    const  onPartyDesign404PageTextColor = (e) =>{
        setTimeout(()=>{
            setPartyDesign404PageTextColor(e.target.value);
        },1000);
    }

    const [partyDesign404PageSecondaryTextColor,setPartyDesign404PageSecondaryTextColor] = useState("#808080");

    const  onPartyDesign404PageSecondaryTextColorChange = (e) =>{
        setTimeout(()=>{
            setPartyDesign404PageSecondaryTextColor(e.target.value);
        },1000);
    }

    const [partyDesign404PageLink,setPartyDesign404PageLink] = useState("https://www.google.com/");

    const  onPartyDesign404PageLinkChange = (e) =>{
        setPartyDesign404PageLink(e.target.value);
    }

    const [tabKey,setTabKey] = useState("jsx");

    const [jsx, setJsx] = useState("");
    const [css, setCss] = useState("");

    useEffect(()=>{
        setJsx(`import React from "react";

import "./404-page-party-design.styles.css";
import partyDesign404PageLogo from "../404-page-party-design.svg";

const PartyDesign404PagePreview = () =>{
    return(
        <div class="party-design-404-page">
            <img class="party-design-404-page-logo-image" src={partyDesign404PageLogo} alt="404 Page"/>
            <p className="party-design-404-page-title" >${partyDesign404PageText}</p>
            <p className="party-design-404-page-text">Sorry can't find the page you are looking for click <a className="party-design-404-page-link" href="${partyDesign404PageLink}">Here</a> to go back to the home page.</p>
        </div>
    );
};

export default PartyDesign404Page;`);
    },[partyDesign404PageText,partyDesign404PageLink]);

    useEffect(()=>{
        setCss(`.party-design-404-page-logo-image{
    height:18.75rem;
    background-color:${partyDesign404PageBackgroundColor};
}

.party-design-404-page{   
    left:50%;
    top:60%;
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction:column;
}

.party-design-404-page-title{
    color:${partyDesign404PageTextColor};
    font-weight:700;
    text-transform:uppercase;
    letter-spacing:2px;
    font-size:2rem;
    margin:50px 0 0 0;
}

.party-design-404-page-text{
    color:${partyDesign404PageSecondaryTextColor};
    font-size:1rem;
    width:60%;
    text-align:center;
    margin:5px;
    font-weight:300;
}

.party-design-404-page-link{
    color:#657eff;
    font-weight:600;
    text-decoration:underline;
}

@media(max-width:900px){
    .party-design-404-page-logo-image{
        height:11.25rem;
    }
    .party-design-404-page-text{
        width:100%;
    }
}

@media(max-width:600px){
    .party-design-404-page{
        width:100%;
    }
    .party-design-404-page-logo-image{
        height:7.5rem;
    }
    .party-design-404-page-title{
        font-size:1.4rem;
    }
    .party-design-404-page-text{
        width:90%;
    }
}`);
    },[partyDesign404PageBackgroundColor,partyDesign404PageTextColor,partyDesign404PageSecondaryTextColor]);

    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            <p className="title-text">404 PAGE - PARTY DESIGN</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="accordian-simple-preview-pane">
                            <div className="accordian-simple-inner-container">
                                <div class="image-text-block-container">
                                <div class="party-design-404-page">
                                    <img style={{backgroundColor:partyDesign404PageBackgroundColor}}class="party-design-404-page-logo-image" src={partyDesign404PageLogo} alt="404 Page"/>
                                    <p style={{color:partyDesign404PageTextColor}}className="party-design-404-page-title" >{partyDesign404PageText}</p>
                                    <p style={{color:partyDesign404PageSecondaryTextColor}} className="party-design-404-page-text">Sorry can't find the page you are looking for. Click <a className="party-design-404-page-link" href={partyDesign404PageLink}>Here</a> to go back to the home page.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Form className="editor-container border border-info">
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Background Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onPartyDesign404PageBackgroundColorChange(e)}}
                                    defaultValue={partyDesign404PageBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Main Text:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <Form.Control defaultValue={partyDesign404PageText} onChange={(e)=>{
                                    onPartyDesign404PageTextChange(e);
                                }} />
                                <span class="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onPartyDesign404PageTextColor(e)}}
                                    defaultValue={partyDesign404PageTextColor}
                                /></span>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Secondary Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span class="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onPartyDesign404PageSecondaryTextColorChange(e)}}
                                    defaultValue={partyDesign404PageSecondaryTextColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Link:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <Form.Control defaultValue={partyDesign404PageLink} onChange={(e)=>{
                                    onPartyDesign404PageLinkChange(e);
                                }} />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                </Form>
                <Row>
                <Col>
                    <div  className="editor-container border border-info">
       
                        <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                            <Tab eventKey="jsx" title="JSX">
                                <ControlledEditor
                                    key="jsx"
                                    value={jsx}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"jsx",
                                        readOnly:true
                                    }}
                                /> 
                            </Tab>
                            <Tab eventKey="css" title="CSS">
                                <ControlledEditor
                                   key="css"
                                   value={css}
                                   options={{
                                       lineWrapping:true,
                                       lint:true,
                                       lineNumbers:true,
                                       theme:"dracula",
                                       mode:"css",
                                       readOnly:true
                                   }}
                               />
                            </Tab>    
                        </Tabs>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="border border-info how-to-container">
                        <span className="how-to-title">How to Use</span>
                        <ol className="">
                            <li>
                                Create a jsx file called <strong>404-page-party-design.jsx</strong> and a css file called <strong>404-page-party-design.css</strong> in your react-app.
                            </li>
                            <li>
                                Copy and paste the code above in to a jsx file and a css file.
                            </li>
                            <li>
                                Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                            </li>
                            <li>
                                Add the line: import PartyDesign404Page from "./404-page-party-design.jsx"; to App.js or any jsx file
                            </li>
                            <li>
                                Import {"<PartyDesign404Page/>"} in to your App.js file
                            </li>
                            <li>
                                Run your react app
                            </li>
                        </ol>
                    </div>
                </Col>
            </Row>    
            </Container>
        </div>
    );
};

export default PartyDesign404Page;