import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./404-simple-page.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";


const Simple404Page = ()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    const [tabKey,setTabKey] = useState("jsx");

    const [text404, setText404] = useState("404");
    const [color404, setColor404] = useState("#000000");
    const [fontWeight404, setFontWeight404] = useState(600);
    const [fontFamily404,setFontFamily404] = useState("sans-serif"); 
    
    const [textPrimary, setTextPrimary] = useState("Page Not Found");
    const [colorPrimary, setColorPrimary] = useState("#000000");
    const [fontWeightPrimary, setFontWeightPrimary] = useState(600);
    const [fontFamilyPrimary,setFontFamilyPrimary] = useState("sans-serif");
    
    const [textSecondary, setTextSecondary] = useState("The Page you are looking for doesn't exist or an other error occured.");
    const [colorSecondary, setColorSecondary] = useState("#000000");
    const [fontWeightSecondary, setFontWeightSecondary] = useState(400);
    const [fontFamilySecondary,setFontFamilySecondary] = useState("sans-serif");    

    const [linkURL, setLinkURL] = useState("https://www.google.com/");

    const  on404TextChange = (e) =>{
        setText404(e.target.value);
    }

    const  onPrimaryTextChange = (e) =>{
        setTextPrimary(e.target.value);
    }

    const  onSecondaryTextChange = (e) =>{
        setTextSecondary(e.target.value);
    }

    const  on404ColorChange = (e) =>{
        setTimeout(()=>{
            setColor404(e.target.value);
        },1000);
    }

    const  onPrimaryColorChange = (e) =>{
        setTimeout(()=>{
            setColorPrimary(e.target.value);
        },1000);
    }

    const  onSecondaryColorChange = (e) =>{
        setTimeout(()=>{
            setColorSecondary(e.target.value);
        },1000);
    }

    const on404FontWeightChange = (e) =>{
        switch(e.target.value){
            case "Normal":
                setFontWeight404(400);
                break;
            case "Bold":
                setFontWeight404(700);
                break;
            case "Extra-Bold":
                setFontWeight404(800);
                break;
            default:
                break ;
                
        }
    }

    const onPrimaryFontWeightChange = (e) =>{
        switch(e.target.value){
            case "Normal":
                setFontWeightPrimary(400);
                break;
            case "Bold":
                setFontWeightPrimary(700);
                break;
            case "Extra-Bold":
                setFontWeightPrimary(800);
                break;
            default:
                break ;
                
        }
    }

    const onSecondaryFontWeightChange = (e) =>{
        switch(e.target.value){
            case "Normal":
                setFontWeightSecondary(400);
                break;
            case "Bold":
                setFontWeightSecondary(700);
                break;
            case "Extra-Bold":
                setFontWeightSecondary(800);
                break;
            default:
                break ;
                
        }
    }

    const on404FontFamilyChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setFontFamily404("Arial Narrow");
                return;
            case "Arial Round":
                setFontFamily404("Arial Round");
                return;
            case "Calibri":
                setFontFamily404("Calibri");
                return;
            case "Cambria":
                setFontFamily404("Cambria");
                return;
            case "Courier":
                setFontFamily404("Courier");
                return;
            case "Franklin Gothic Medium":
                setFontFamily404("Franklin Gothic Medium");
                return;
            case "Garamond":
                setFontFamily404("Garamond");
                return;
            case "Geneva":
                setFontFamily404("Geneva");
                return;
            case "Georgia":
                setFontFamily404("Georgia");
                return;
            case "Gill Sans":
                setFontFamily404("Gill Sans");
                return;
            case "Impact":
                setFontFamily404("Impact");
                return;
            case "Lucida Sans":
                setFontFamily404("Lucida Sans");
                return;
            case "Sans Serif":
                setFontFamily404("sans-serif");
                return;
            case "Segoe UI":
                setFontFamily404("Segoe UI");
                return;
            case "Times New Roman":
                setFontFamily404("Times New Roman");
                return;
            case "Trebuchet MS":
                setFontFamily404("Trebuchet MS");
                return;
            case "Verdana":
                setFontFamily404("Verdana");
                return;
            default:
                return;    
        }
    }

    const onPrimaryFontFamilyChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setFontFamilyPrimary("Arial Narrow");
                return;
            case "Arial Round":
                setFontFamilyPrimary("Arial Round");
                return;
            case "Calibri":
                setFontFamilyPrimary("Calibri");
                return;
            case "Cambria":
                setFontFamilyPrimary("Cambria");
                return;
            case "Courier":
                setFontFamilyPrimary("Courier");
                return;
            case "Franklin Gothic Medium":
                setFontFamilyPrimary("Franklin Gothic Medium");
                return;
            case "Garamond":
                setFontFamilyPrimary("Garamond");
                return;
            case "Geneva":
                setFontFamilyPrimary("Geneva");
                return;
            case "Georgia":
                setFontFamilyPrimary("Georgia");
                return;
            case "Gill Sans":
                setFontFamilyPrimary("Gill Sans");
                return;
            case "Impact":
                setFontFamilyPrimary("Impact");
                return;
            case "Lucida Sans":
                setFontFamilyPrimary("Lucida Sans");
                return;
            case "Sans Serif":
                setFontFamilyPrimary("sans-serif");
                return;
            case "Segoe UI":
                setFontFamilyPrimary("Segoe UI");
                return;
            case "Times New Roman":
                setFontFamilyPrimary("Times New Roman");
                return;
            case "Trebuchet MS":
                setFontFamilyPrimary("Trebuchet MS");
                return;
            case "Verdana":
                setFontFamilyPrimary("Verdana");
                return;
            default:
                return;    
        }
    }

    const onSecondaryFontFamilyChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setFontFamilySecondary("Arial Narrow");
                return;
            case "Arial Round":
                setFontFamilySecondary("Arial Round");
                return;
            case "Calibri":
                setFontFamilySecondary("Calibri");
                return;
            case "Cambria":
                setFontFamilySecondary("Cambria");
                return;
            case "Courier":
                setFontFamilySecondary("Courier");
                return;
            case "Franklin Gothic Medium":
                setFontFamilySecondary("Franklin Gothic Medium");
                return;
            case "Garamond":
                setFontFamilySecondary("Garamond");
                return;
            case "Geneva":
                setFontFamilySecondary("Geneva");
                return;
            case "Georgia":
                setFontFamilySecondary("Georgia");
                return;
            case "Gill Sans":
                setFontFamilySecondary("Gill Sans");
                return;
            case "Impact":
                setFontFamilySecondary("Impact");
                return;
            case "Lucida Sans":
                setFontFamilySecondary("Lucida Sans");
                return;
            case "Sans Serif":
                setFontFamilySecondary("sans-serif");
                return;
            case "Segoe UI":
                setFontFamilySecondary("Segoe UI");
                return;
            case "Times New Roman":
                setFontFamilySecondary("Times New Roman");
                return;
            case "Trebuchet MS":
                setFontFamilySecondary("Trebuchet MS");
                return;
            case "Verdana":
                setFontFamilySecondary("Verdana");
                return;
            default:
                return;    
        }
    }

    

    const [jsx, setJsx] = useState("");

    const [css, setCss] = useState("");
    
    useEffect(()=>{
        setJsx(`import React from "react";
import "./404-simple-page.css";
            
const Simple404Page = ()=> {

    return (
        <div className="container">
            <h1>${text404}</h1>
            <h2>${textPrimary}</h2>
            <p>${textSecondary}<a href="${linkURL}">Click Here.</a></p>
        </div>
    );
}
    
export default Simple404Page;`);

    },[text404, textPrimary,textSecondary,linkURL]);

    useEffect(()=>{

        setCss(`.container-404{
    margin: 0 auto;
    margin-top:15%;
    height:100vh;
    width:100vw;
}

h1{
    font-size:6rem;
    font-weight:${fontWeight404};
    text-align:center;
    font-family:${fontFamily404};
    color:${color404};
}
h2{
    font-size:1.5rem;
    text-align:center;
    color:${colorPrimary};
    font-weight:${fontWeightPrimary};
    font-family:${fontFamilyPrimary};
}

p{
    text-align:center;
    font-family:${fontFamilySecondary};
    font-size:1rem;
    color:${colorSecondary};
    font-weight:${fontWeightSecondary};
}`);
    },[color404,fontWeight404,fontFamily404,colorPrimary,fontWeightPrimary,fontFamilyPrimary,colorSecondary,fontWeightSecondary,fontFamilySecondary]);
    const  onLinkURLChange = (e) =>{
        setLinkURL(e.target.value);
    }
    
  
 return (
     <div>
         <Container>
            <Row>
                <Col>
                    <div className="title-pane">
                        
                        <p className="title-text">SIMPLE 404 PAGE GENERATOR</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="preview-pane">
                        <div className="inner-container">
                            <h1 style={{color:color404,fontWeight:fontWeight404,fontFamily:fontFamily404}} >{text404}</h1>
                            <h2 style={{color:colorPrimary,fontWeight:fontWeightPrimary,fontFamily:fontFamilyPrimary}}>{textPrimary}</h2>
                            <p style={{color:colorSecondary,fontWeight:fontWeightSecondary,fontFamily:fontFamilySecondary}}>{textSecondary} <a style={{fontWeight:fontWeightSecondary,fontFamily:fontFamilySecondary}}  href={linkURL}>Click here.</a></p>
                        </div>
                    </div>
                </Col>
            </Row>
           
           <Form className="editor-container border border-info">
                <Form.Group  as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        404 Text:
                    </Form.Label>

                    <Col sm="4">
                        <InputGroup>
                            <Form.Control defaultValue={text404} onChange={(e)=>{
                                on404TextChange(e);
                            }} />
                            <span class="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{on404ColorChange(e)}}
                                defaultValue={color404}
                            /></span>
                        </InputGroup>
                    </Col>

                    <Col sm="3">
                        <Form.Control defaultValue="Bold" onChange={(e)=>{on404FontWeightChange(e)}} as="select">
                            <option>Normal</option>
                            <option>Bold</option>
                            <option>Extra-Bold</option>
                        </Form.Control>
                        
                    </Col>
                    <Col sm="3">
                        <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                on404FontFamilyChange(e);
                            }} as="select">
                            <option>Arial Narrow</option>
                            <option>Arial Round</option>
                            <option>Calibri</option>
                            <option>Cambria</option>
                            <option>Courier</option>
                            <option>Franklin Gothic Medium</option>
                            <option>Garamond</option>
                            <option>Geneva</option>
                            <option>Georgia</option>
                            <option>Gill Sans</option>
                            <option>Impact</option>
                            <option>Lucida Sans</option>
                            <option>Sans Serif</option>
                            <option>Segoe UI</option>
                            <option>Times New Roman</option>
                            <option>Trebuchet MS</option>
                            <option>Verdana</option>
                        </Form.Control>
                        
                    </Col>                 
                </Form.Group>

                <Form.Group  as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        Primary Text:
                    </Form.Label>

                    <Col sm="4">
                        <InputGroup>
                            <Form.Control defaultValue={textPrimary} onChange={(e)=>{
                                onPrimaryTextChange(e);
                            }}/>
                            <span class="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{onPrimaryColorChange(e)}}
                                defaultValue={colorPrimary}
                            /></span>
                        </InputGroup>
                    </Col>

                    <Col sm="3">
                        <Form.Control defaultValue="Bold" onChange={(e)=>{onPrimaryFontWeightChange(e)}} as="select">
                            <option>Normal</option>
                            <option>Bold</option>
                            <option>Extra-Bold</option>
                        </Form.Control>
                        
                    </Col>
                    <Col sm="3">
                        <Form.Control  defaultValue="Sans Serif" onChange={(e)=>{
                                onPrimaryFontFamilyChange(e);
                            }} as="select">
                            <option>Arial Narrow</option>
                            <option>Arial Round</option>
                            <option>Calibri</option>
                            <option>Cambria</option>
                            <option>Courier</option>
                            <option>Franklin Gothic Medium</option>
                            <option>Garamond</option>
                            <option>Geneva</option>
                            <option>Georgia</option>
                            <option>Gill Sans</option>
                            <option>Impact</option>
                            <option>Lucida Sans</option>
                            <option>Sans Serif</option>
                            <option>Segoe UI</option>
                            <option>Times New Roman</option>
                            <option>Trebuchet MS</option>
                            <option>Verdana</option>
                        </Form.Control>
                        
                    </Col>                 
                </Form.Group>

                <Form.Group  as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        Secondary Text:
                    </Form.Label>

                    <Col sm="4">
                        <InputGroup>
                            <Form.Control defaultValue={textSecondary} onChange={(e)=>{
                                onSecondaryTextChange(e);
                            }}  />
                            <span class="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{onSecondaryColorChange(e)}}
                                defaultValue={colorSecondary}
                            /></span>
                        </InputGroup>
                    </Col>

                    <Col sm="3">
                        <Form.Control defaultValue="Normal"  onChange={(e)=>{onSecondaryFontWeightChange(e)}} as="select">
                            <option>Normal</option>
                            <option>Bold</option>
                            <option>Extra-Bold</option>
                        </Form.Control>
                        
                    </Col>
                    <Col sm="3">
                        <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                onSecondaryFontFamilyChange(e);
                            }} as="select">
                        <option>Arial Narrow</option>
                            <option>Arial Round</option>
                            <option>Calibri</option>
                            <option>Cambria</option>
                            <option>Courier</option>
                            <option>Franklin Gothic Medium</option>
                            <option>Garamond</option>
                            <option>Geneva</option>
                            <option>Georgia</option>
                            <option>Gill Sans</option>
                            <option>Impact</option>
                            <option>Lucida Sans</option>
                            <option>Sans Serif</option>
                            <option>Segoe UI</option>
                            <option>Times New Roman</option>
                            <option>Trebuchet MS</option>
                            <option>Verdana</option>
                        </Form.Control>
                        
                    </Col>                 
                </Form.Group>

                <Form.Group  as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        Link:
                    </Form.Label>

                    <Col sm="4">
                        <InputGroup>
                            
                            <Form.Control defaultValue={linkURL} onChange={(e)=>{
                                onLinkURLChange(e);
                            }} />
                          
                        </InputGroup>
                    </Col>                
                </Form.Group>
            </Form>
            <Row>
                <Col>
                    <div  className="editor-container border border-info">
       
                        <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                            <Tab eventKey="jsx" title="JSX">
                                <ControlledEditor
                                    key="jsx"
                                    value={jsx}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"jsx",
                                        readOnly:true
                                    }}
                                /> 
                            </Tab>
                            <Tab eventKey="css" title="CSS">
                                <ControlledEditor
                                   key="css"
                                   value={css}
                                   options={{
                                       lineWrapping:true,
                                       lint:true,
                                       lineNumbers:true,
                                       theme:"dracula",
                                       mode:"css",
                                       readOnly:true
                                   }}
                               />
                            </Tab>    
                        </Tabs>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="border border-info how-to-container">
                        <span className="how-to-title">How to Use</span>
                        <ol className="">
                            <li>
                                Create a jsx file called <strong>404-simple-page.jsx</strong> and a css file called <strong>404-simple-page.css</strong> in your react-app.
                            </li>
                            <li>
                                Copy and paste the code above in to a jsx file and a css file.
                            </li>
                            <li>
                                Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                            </li>
                            <li>
                                Add the line: import Simple404Page from "./404-simple-page.jsx"; to App.js or any jsx file
                            </li>
                            <li>
                                Import {"<Simple404Page/>"} in to your App.js file
                            </li>
                            <li>
                                Run your react app
                            </li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </Container>
     </div>
 )
}

export default Simple404Page;