import React, {useState} from "react";

import "./badge-simple-preview.styles.scss";

const BadgeSimplePreview = () =>{
    return(
        <span className="badge">badge preview simple</span> 
    );
}

export default BadgeSimplePreview;