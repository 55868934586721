import React, {useEffect} from "react";
import "./homepage.styles.scss";
import {Row, Col, Container} from 'react-bootstrap';
import { useHistory } from "react-router";
import {Link} from "react-router-dom";
import { Button} from 'react-bootstrap';
const Homepage = () => {
	const history = useHistory();

	useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

	const cardsClick = (e)=>{
		switch(e.target.value){
			case "card1":
				history.push("/404-page-party-design")
				break;
			case "card2":
				history.push("/header-simple")
				break;
			case "card3":
				history.push("/gallery-simple")
				break;
			case "card4":
				history.push("/404-simple-page")
				break;
			case "card5":
				history.push("/header-simple")
				break;
			case "card6":
				history.push("/gallery-simple")
				break;
			case "explore":
				history.push("/components-list")
				break;
			case "subscribe":
				history.push("https://www.patreon.com/bePatron?u=89360940")
				break;
			default:
				break;
		}
	}

	
	
	return (
		<>
				<Container>
					<section className="hero">
						<Row>
							<Col lg="6">
								<div className="copy">									
									<div className="text-hero-bold">
										Speed up your React Development.
									</div>
									<div className="text-hero-regular">
										Get access to customizable and responsive React components that can be easily integrated to your React project.
									</div>
									<div class="cta">
										<Button value="explore" onClick={cardsClick} className="btn btn1 btn-primary shadow-none">Explore Now</Button>
										<a href="https://www.patreon.com/bePatron?u=89360940"><Button value="subscribe" className="btn btn1 btn-subscribe  shadow-none ml-3">Become a patron</Button></a>
									</div>
								</div>
							</Col>
							<Col lg="4">
								<img className="homepage-hero-image" src={require("../../assets/images/homepage/hero-image.jpg").default} alt=""/>
							</Col>
						</Row>
					</section>
					<Row>
						<Col>
							<h1 className="homepage-latest-section">CHECK OUT THE LATEST COMPONENTS</h1>
						</Col>
					</Row>
					<Row>
						<Col lg="4">
							<div className="card">
								<div className="card__body">
									<img onClick={()=>{history.push("/404-page-party-design")}} className="card__image1" alt="card" src={require("../../assets/images/homepage/cards/card-1.PNG").default}/>
								</div>
								<Button value="card1" className="btn card__btn" onClick={cardsClick} >View Component</Button>
							</div>
						</Col>
						<Col lg="4">
							<div className="card">
								<div onClick={()=>{history.push("/header-simple")}} className="card__body">
									<img  className="card__image2" alt="card" src={require("../../assets/images/homepage/cards/card-2.PNG").default}/>
								</div>
								<Button value="card2" onClick={cardsClick} className="btn card__btn" >View Component</Button>
							</div>
						</Col>
						<Col lg="4">
							<div className="card">
								<div className="card__body">
									<img onClick={()=>{history.push("/gallery-simple")}} className="card__image3" alt="card" src={require("../../assets/images/homepage/cards/card-3.PNG").default}/>
								</div>
								<Button value="card3" onClick={cardsClick} className="btn card__btn" >View Component</Button>
							</div>
						</Col>
					</Row>
					<Row className="free-section">
						<Col>
							<h1 className="homepage-latest-section">TRY OUT THESE FREE COMPONENTS</h1>
						</Col>
					</Row>
					<Row className="free-section-cards">
						<Col lg="4">
							<div className="card">
								<div onClick={()=>{history.push("/404-simple-page")}} className="card__body">
									<img  className="card__image4" alt="card" src={require("../../assets/images/homepage/cards/card-4.png").default}/>
								</div>
								<Button value="card4" onClick={cardsClick} className="btn card__btn">View Component</Button>
							</div>
						</Col>
						<Col lg="4">
							<div className="card">
								<div onClick={()=>{history.push("/header-simple")}} className="card__body">
									<img onClick={()=>{history.push("/header-simple")}} className="card__image5" alt="card" src={require("../../assets/images/homepage/cards/card-5.PNG").default}/>
								</div>
								<Button value="card5" onClick={cardsClick} className="btn card__btn">View Component</Button>
							</div>
						</Col>
						<Col lg="4">
							<div className="card">
								<div className="card__body">
									<img onClick={()=>{history.push("gallery-simple")}} className="card__image6" alt="card" src={require("../../assets/images/homepage/cards/card-6.PNG").default}/>
								</div>
								<Button value="card6" onClick={cardsClick} className="btn card__btn">View Component</Button>
							</div>
						</Col>
					</Row>
				</Container>
		</>
	)
}

export default Homepage;