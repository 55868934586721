import React, {useState} from "react";

import "./form-simple-preview.styles.scss";

const FormSimplePreview = () =>{
    return(
        <div className="form-simple">
            <div className="form-simple-title">
                Welcome
            </div>
            <div className="form-simple-subtitle">
                Let's Create your account!
            </div>
            <div className="form-simple-input-container form-simple-ic1">
                <input id="form-simple-firstname" className="form-simple-input" type="text" placeholder="First Name" />
            </div>
            <div className="form-simple-input-container form-simple-ic2">
                <input id="form-simple-lastname" className="form-simple-input" type="text" placeholder="Last Name" />
                <div className="form-simple-cut"></div>
            </div>
            <div className="form-simple-input-container form-simple-ic2">
                <input id="form-simple-email" className="form-simple-input" type="email" placeholder="Email" />
            </div>
            <button type="text" className="form-simple-submit">
                submit
            </button>
        </div> 
    );
}

export default FormSimplePreview;