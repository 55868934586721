import React from "react";
import "./404-simple-page-preview.styles.scss";
            
const Simple404PagePreview = ()=> {

    return (
        <div className="container">
            <h1>404</h1>
            <h2>Page Not Founddcssdfsdfd</h2>
            <p>The Page zdfsdfdsfare looking for doesn't exist or an other error occured.<a href="https://www.google.com/">Click Here.</a></p>
        </div>
    );
}
    
export default Simple404PagePreview;