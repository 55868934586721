import React, {useState, useEffect} from "react";
import { Form, FormControl, Navbar, Nav, Button, InputGroup} from 'react-bootstrap';
import {Link} from "react-router-dom";
import "./header.styles.scss";
import userIcon from '../../assets/images/user-icon.svg';
import { useHistory } from "react-router";

const Header = ({currentUser, subscriptionRole}) => {
	const [checkBoxChecked,setCheckBoxChecked] = useState(false);

	const history = useHistory();

	const handleChange = (event) =>{
		setCheckBoxChecked(prevState => !prevState);
	
	}
	
	const handleSubmit = async (event) =>{
		if(checkBoxChecked){
			return;
		}
	}


	
	return (		
		
			<Navbar className="container-fluid" collapseOnSelect expand="xl">
				<Navbar.Brand className="navbarBrand" as={Link} to={"/"}>REACT COMPONENTS GENERATOR</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" /> 
				<Navbar.Collapse className="nav-margin" id="responsive-navbar-nav">
					
					<Nav.Link className=" header-component-list" as={Link} to={"/components-list"}>
						Components List
					</Nav.Link>
					<div>
						<a className="ml-3 header-contact" href="https://twitter.com/ComponentReact">
							Contact
						</a>
					</div>
					<div>
						<a className="ml-3 header-patron" href="https://www.patreon.com/bePatron?u=89360940">
							Become a Patron
						</a>
					</div>
						
					
					{/* <Form onSubmit={handleSubmit}>						
						<InputGroup>
							<FormControl type="text" placeholder="Components"  />
							<Form.Group controlId="formBasicCheckbox">
								<Form.Check onChange={handleChange} tabIndex="-1" className="checkbox" type="checkbox" label="Check me out" />
							</Form.Group>
							<InputGroup.Append>
								<Button type="submit" variant="outline-primary">Search</Button>
							</InputGroup.Append>
						</InputGroup>					
					</Form> */}
					
					{/* {
						currentUser ? 
						<>
							<Nav.Link className="ml-auto" as={Link} to={"/user-page"} > 
								<img className="user-icon" src={userIcon} alt="user icon" />
							</Nav.Link>
							
							<Nav.Link className="ml-auto" onClick={()=> auth.signOut().then(()=>{history.push({pathname:"/"}); history.go(0);})}>
								Sign Out
							</Nav.Link>
						</>
						:
						<>
							<Nav.Link className="ml-auto" as={Link} to={'/login'}>
								Login
							</Nav.Link>
							
							<Nav.Link className="ml-auto" as={Link} to={'/signup'}>Sign Up</Nav.Link>
						</>
					} */}
					{/* {
						
						checkSubscriptionRole ?
						null

						:  */}
						{/* <Button type="button" className="ml-auto btn btn-primary">Subscribe</Button> */}

					{/* } */}
				</Navbar.Collapse>
			</Navbar>
		
	)
};

export default Header;