import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./card-simple.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";

const CardSimple = ()=>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [tabKey,setTabKey] = useState("jsx");

    const [cardSimpleText,setCardSimpleText] = useState("Card Title");

    const onCardSimpleTextChange = (e) =>{
        setCardSimpleText(e.target.value);
    }

    const [cardSimpleTitleFont, setCardSimpleTitleFont] = useState("Sans-Serif");

    const oncardSimpleTitleFontChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setCardSimpleTitleFont("Arial Narrow");
                return;
            case "Arial Round":
                setCardSimpleTitleFont("Arial Round");
                return;
            case "Calibri":
                setCardSimpleTitleFont("Calibri");
                return;
            case "Cambria":
                setCardSimpleTitleFont("Cambria");
                return;
            case "Courier":
                setCardSimpleTitleFont("Courier");
                return;
            case "Franklin Gothic Medium":
                setCardSimpleTitleFont("Franklin Gothic Medium");
                return;
            case "Garamond":
                setCardSimpleTitleFont("Garamond");
                return;
            case "Geneva":
                setCardSimpleTitleFont("Geneva");
                return;
            case "Georgia":
                setCardSimpleTitleFont("Georgia");
                return;
            case "Gill Sans":
                setCardSimpleTitleFont("Gill Sans");
                return;
            case "Impact":
                setCardSimpleTitleFont("Impact");
                return;
            case "Lucida Sans":
                setCardSimpleTitleFont("Lucida Sans");
                return;
            case "Sans Serif":
                setCardSimpleTitleFont("sans-serif");
                return;
            case "Segoe UI":
                setCardSimpleTitleFont("Segoe UI");
                return;
            case "Times New Roman":
                setCardSimpleTitleFont("Times New Roman");
                return;
            case "Trebuchet MS":
                setCardSimpleTitleFont("Trebuchet MS");
                return;
            case "Verdana":
                setCardSimpleTitleFont("Verdana");
                return;
            default:
                return;    
        }
    }

    const [cardSimpleFontWeight,setCardSimpleFontWeight] = useState(600);

    const onCardSimpleFontWeight = (e)=>{
        switch(e.target.value){
            case "Normal":
                setCardSimpleFontWeight(400);
                break;
            case "Bold":
                setCardSimpleFontWeight(600);
                break;
            case "Extra-Bold":
                setCardSimpleFontWeight(800);
                break;
            default:
                break ;
                
        }
    }

    const [cardSimpleTitleColor, setCardSimpleTitleColor] = useState("#000000");

    const onCardSimpleTitleColorChange = (e)=>{
        setTimeout(()=>{
            setCardSimpleTitleColor(e.target.value)
        },1000); 
    }

    const [cardSimpleText1, setCardSimpleText1] = useState("Some text.");

    const onCardSimpleText1Change = (e) =>{
        setCardSimpleText1(e.target.value);
    }

    const [cardSimpleText1Color, setCardSimpleText1Color] = useState("#000000");

    const onCardSimpleText1ColorChange = (e)=>{
        setTimeout(()=>{
            setCardSimpleText1Color(e.target.value)
        },1000); 
    }

    const [cardSimpleText2, setCardSimpleText2] = useState("Some text.");

    const onCardSimpleText2Change = (e) =>{
        setCardSimpleText2(e.target.value);
    }

    const [cardSimpleText2Color, setCardSimpleText2Color] = useState("#000000");

    const onCardSimpleText2ColorChange = (e)=>{
        setTimeout(()=>{
            setCardSimpleText2Color(e.target.value)
        },1000); 
    }

    const [cardSimpleText1Font, setCardSimpleText1Font] = useState("Sans-Serif");

    const oncardSimpleText1FontChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setCardSimpleText1Font("Arial Narrow");
                return;
            case "Arial Round":
                setCardSimpleText1Font("Arial Round");
                return;
            case "Calibri":
                setCardSimpleText1Font("Calibri");
                return;
            case "Cambria":
                setCardSimpleText1Font("Cambria");
                return;
            case "Courier":
                setCardSimpleText1Font("Courier");
                return;
            case "Franklin Gothic Medium":
                setCardSimpleText1Font("Franklin Gothic Medium");
                return;
            case "Garamond":
                setCardSimpleText1Font("Garamond");
                return;
            case "Geneva":
                setCardSimpleText1Font("Geneva");
                return;
            case "Georgia":
                setCardSimpleText1Font("Georgia");
                return;
            case "Gill Sans":
                setCardSimpleText1Font("Gill Sans");
                return;
            case "Impact":
                setCardSimpleText1Font("Impact");
                return;
            case "Lucida Sans":
                setCardSimpleText1Font("Lucida Sans");
                return;
            case "Sans Serif":
                setCardSimpleText1Font("sans-serif");
                return;
            case "Segoe UI":
                setCardSimpleText1Font("Segoe UI");
                return;
            case "Times New Roman":
                setCardSimpleText1Font("Times New Roman");
                return;
            case "Trebuchet MS":
                setCardSimpleText1Font("Trebuchet MS");
                return;
            case "Verdana":
                setCardSimpleText1Font("Verdana");
                return;
            default:
                return;    
        }
    }

    const [cardSimpleText2Font, setCardSimpleText2Font] = useState("Sans-Serif");

    const oncardSimpleText2FontChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setCardSimpleText2Font("Arial Narrow");
                return;
            case "Arial Round":
                setCardSimpleText2Font("Arial Round");
                return;
            case "Calibri":
                setCardSimpleText2Font("Calibri");
                return;
            case "Cambria":
                setCardSimpleText2Font("Cambria");
                return;
            case "Courier":
                setCardSimpleText2Font("Courier");
                return;
            case "Franklin Gothic Medium":
                setCardSimpleText2Font("Franklin Gothic Medium");
                return;
            case "Garamond":
                setCardSimpleText2Font("Garamond");
                return;
            case "Geneva":
                setCardSimpleText2Font("Geneva");
                return;
            case "Georgia":
                setCardSimpleText2Font("Georgia");
                return;
            case "Gill Sans":
                setCardSimpleText2Font("Gill Sans");
                return;
            case "Impact":
                setCardSimpleText2Font("Impact");
                return;
            case "Lucida Sans":
                setCardSimpleText2Font("Lucida Sans");
                return;
            case "Sans Serif":
                setCardSimpleText2Font("sans-serif");
                return;
            case "Segoe UI":
                setCardSimpleText2Font("Segoe UI");
                return;
            case "Times New Roman":
                setCardSimpleText2Font("Times New Roman");
                return;
            case "Trebuchet MS":
                setCardSimpleText2Font("Trebuchet MS");
                return;
            case "Verdana":
                setCardSimpleText2Font("Verdana");
                return;
            default:
                return;    
        }
    }

    const [cardSimpleText1FontWeight,setCardSimpleText1FontWeight] = useState(400);

    const onCardSimpleText1FontWeightChange = (e)=>{
        switch(e.target.value){
            case "Normal":
                setCardSimpleText1FontWeight(400);
                break;
            case "Bold":
                setCardSimpleText1FontWeight(600);
                break;
            case "Extra-Bold":
                setCardSimpleText1FontWeight(800);
                break;
            default:
                break ;
                
        }
    }

    const [cardSimpleText2FontWeight,setCardSimpleText2FontWeight] = useState(400);

    const onCardSimpleText2FontWeightChange = (e)=>{
        switch(e.target.value){
            case "Normal":
                setCardSimpleText2FontWeight(400);
                break;
            case "Bold":
                setCardSimpleText2FontWeight(600);
                break;
            case "Extra-Bold":
                setCardSimpleText2FontWeight(800);
                break;
            default:
                break ;
                
        }
    }

    const [cardSimpleButtonText,setCardSimpleButtonText] = useState("Read More");

    const onCardSimpleButtonTextChange = (e) =>{
        setCardSimpleButtonText(e.target.value);
    }

    const [cardSimpleButtonTextColor, setCardSimpleButtonTextColor] = useState("#0e0e10");

    const onCardSimpleButtonTextColorChange = (e)=>{
        setTimeout(()=>{
            setCardSimpleButtonTextColor(e.target.value)
        },1000); 
    }

    const [cardSimpleButtonBackgroundColor, setCardSimpleButtonBackgroundColor] = useState("#0000000d");

    const onCardSimpleButtonBackgroundColorChange = (e)=>{
        setTimeout(()=>{
            setCardSimpleButtonBackgroundColor(e.target.value)
        },1000); 
    }

    const [cardSimpleButtonFont, setCardSimpleButtonFont] = useState("Sans-Serif");

    const oncardSimpleButtonFontChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setCardSimpleButtonFont("Arial Narrow");
                return;
            case "Arial Round":
                setCardSimpleButtonFont("Arial Round");
                return;
            case "Calibri":
                setCardSimpleButtonFont("Calibri");
                return;
            case "Cambria":
                setCardSimpleButtonFont("Cambria");
                return;
            case "Courier":
                setCardSimpleButtonFont("Courier");
                return;
            case "Franklin Gothic Medium":
                setCardSimpleButtonFont("Franklin Gothic Medium");
                return;
            case "Garamond":
                setCardSimpleButtonFont("Garamond");
                return;
            case "Geneva":
                setCardSimpleButtonFont("Geneva");
                return;
            case "Georgia":
                setCardSimpleButtonFont("Georgia");
                return;
            case "Gill Sans":
                setCardSimpleButtonFont("Gill Sans");
                return;
            case "Impact":
                setCardSimpleButtonFont("Impact");
                return;
            case "Lucida Sans":
                setCardSimpleButtonFont("Lucida Sans");
                return;
            case "Sans Serif":
                setCardSimpleButtonFont("sans-serif");
                return;
            case "Segoe UI":
                setCardSimpleButtonFont("Segoe UI");
                return;
            case "Times New Roman":
                setCardSimpleButtonFont("Times New Roman");
                return;
            case "Trebuchet MS":
                setCardSimpleButtonFont("Trebuchet MS");
                return;
            case "Verdana":
                setCardSimpleButtonFont("Verdana");
                return;
            default:
                return;    
        }
    }

    const [cardSimpleButtonFontWeight,setCardSimpleButtonFontWeight] = useState(600);

    const onCardSimpleButtonFontWeightChange = (e)=>{
        switch(e.target.value){
            case "Normal":
                setCardSimpleButtonFontWeight(400);
                break;
            case "Bold":
                setCardSimpleButtonFontWeight(600);
                break;
            case "Extra-Bold":
                setCardSimpleButtonFontWeight(800);
                break;
            default:
                break ;
                
        }
    }

    const [cardSimpleBackgroundColor,setCardSimpleBackgroundColor]=useState("#f1f1f1");

    const onCardSimpleBackgroundColor = (e)=>{
        setTimeout(()=>{
            setCardSimpleBackgroundColor(e.target.value)
        },1000); 
    }

    const [linkURL, setLinkURL] = useState("https://www.google.com/");

    const  onLinkURLChange = (e) =>{
        setLinkURL(e.target.value);
    }

    const [jsx, setJsx] = useState("");
    const [css, setCss] = useState("");

    useEffect(()=>{
        setJsx(`import React from "react";

import "./card-simple.css";

const CardSimple = ()=>{
    return(
        <div className="card-simple-column">
            <div className="card-simple">
                <span className="card-simple-title">${cardSimpleText}</span>
                <p className="card-simple-text card-simple-text-1">${cardSimpleText1}</p>
                <p className="card-simple-text">${cardSimpleText2}</p>
                <a href="${linkURL}"><button className="card-simple-button">${cardSimpleButtonText}</button></a>
            </div>
        </div>
    );
}

export default CardSimple;`);
    },[linkURL,cardSimpleText,cardSimpleText1,cardSimpleText2,cardSimpleButtonText]);

    useEffect(()=>{
        setCss(`.card-simple {
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
    padding: 16px;
    text-align: center;
    background-color: ${cardSimpleBackgroundColor};
}

.card-simple-text{
    font-weight:normal;
}

.card-simple-column {
    float: left;
    width: 25%;
    padding: 0 10px;
}

.card-simple-title{
    display: block;
    font-size: 1.17rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: ${cardSimpleFontWeight};
    font-family:${cardSimpleTitleFont};
    color:${cardSimpleTitleColor};
}

.card-simple-text-1{
    color:${cardSimpleText1Color};
    font-family:${cardSimpleText1Font};
    font-weight:${cardSimpleText1FontWeight};
}

.card-simple-text-2{
    color:${cardSimpleText2Color};
    font-family:${cardSimpleText2Font};
    font-weight:${cardSimpleText2FontWeight};
}

.card-simple-button{ 
    outline: 0;
    border: none;
    cursor: pointer;
    font-weight:${cardSimpleButtonFontWeight};
    border-radius: 4px;
    font-size: 13px;
    background-color: ${cardSimpleButtonBackgroundColor};
    color: ${cardSimpleButtonTextColor};
    padding:10px 20px;
    font-family:${cardSimpleButtonFont};
}

.card-simple-button:hover {
    background-color: #0000001a;
}`);
    },[cardSimpleBackgroundColor,cardSimpleTitleFont,cardSimpleFontWeight,cardSimpleTitleColor,cardSimpleText1Color,cardSimpleText2Color,cardSimpleText1FontWeight,cardSimpleText1Font,cardSimpleText2Font,cardSimpleButtonTextColor,cardSimpleText2FontWeight,cardSimpleButtonBackgroundColor,cardSimpleButtonFont,cardSimpleButtonFontWeight]);

    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            <p className="title-text">CARD-SIMPLE GENERATOR</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className="accordian-simple-preview-pane">
                            <div className="card-simple-inner-center accordian-simple-inner-container ">
                                <div style={{backgroundColor:cardSimpleBackgroundColor}} className="card-simple">
                                    <span style={{fontFamily:cardSimpleTitleFont,fontWeight:cardSimpleFontWeight,color:cardSimpleTitleColor}} className="card-simple-title">{cardSimpleText}</span>
                                    <p style={{fontFamily:cardSimpleText1Font,color:cardSimpleText1Color, fontWeight:cardSimpleText1FontWeight}} className="card-simple-text">{cardSimpleText1}</p>
                                    <p style={{fontFamily:cardSimpleText2Font, color:cardSimpleText2Color, fontWeight:cardSimpleText2FontWeight}} className="card-simple-text">{cardSimpleText2}</p>
                                    <a href={linkURL}><button style={{color:cardSimpleButtonTextColor, backgroundColor:cardSimpleButtonBackgroundColor, fontFamily:cardSimpleButtonFont, fontWeight:cardSimpleButtonFontWeight}} className="card-simple-button">{cardSimpleButtonText}</button></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Form className="editor-container border border-info">
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Card Background Color:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onCardSimpleBackgroundColor(e)}}
                                    defaultValue={cardSimpleBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="3">
                            Button Link:
                        </Form.Label>
                        <Col sm="3">
                        <InputGroup>
                            
                            <Form.Control defaultValue={linkURL} onChange={(e)=>{
                                onLinkURLChange(e);
                            }} />
                          
                        </InputGroup>
                    </Col> 
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Card Title Text:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <Form.Control defaultValue={cardSimpleText} onChange={(e)=>{
                                    onCardSimpleTextChange(e);
                                }}/>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="3">
                            Card Title Font:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                    oncardSimpleTitleFontChange(e);
                                }} as="select">
                                <option>Arial Narrow</option>
                                <option>Arial Round</option>
                                <option>Calibri</option>
                                <option>Cambria</option>
                                <option>Courier</option>
                                <option>Franklin Gothic Medium</option>
                                <option>Garamond</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Gill Sans</option>
                                <option>Impact</option>
                                <option>Lucida Sans</option>
                                <option>Sans Serif</option>
                                <option>Segoe UI</option>
                                <option>Times New Roman</option>
                                <option>Trebuchet MS</option>
                                <option>Verdana</option>
                            </Form.Control>
                        </Col>          
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Card Title Weight:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue={cardSimpleFontWeight} onChange={(e)=>{onCardSimpleFontWeight(e)}} as="select">
                                <option>Normal</option>
                                <option>Bold</option>
                                <option>Extra-Bold</option>
                            </Form.Control>
                        </Col>
                        <Form.Label className="label-text" column sm="3">
                            Card Title Color:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onCardSimpleTitleColorChange(e)}}
                                    defaultValue={cardSimpleTitleColor}
                                /></span>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Card Text 1:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <Form.Control defaultValue={cardSimpleText1} onChange={(e)=>{
                                    onCardSimpleText1Change(e);
                                }}/>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onCardSimpleText1ColorChange(e)}}
                                    defaultValue={cardSimpleText1Color}
                                /></span>
                            </InputGroup>
                        </Col>

                        <Form.Label className="label-text" column sm="3">
                            Card Text 2:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <Form.Control defaultValue={cardSimpleText2} onChange={(e)=>{
                                    onCardSimpleText2Change(e);
                                }}/>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onCardSimpleText2ColorChange(e)}}
                                    defaultValue={cardSimpleText2Color}
                                /></span>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Card Text 1 Font:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                    oncardSimpleText1FontChange(e);
                                }} as="select">
                                <option>Arial Narrow</option>
                                <option>Arial Round</option>
                                <option>Calibri</option>
                                <option>Cambria</option>
                                <option>Courier</option>
                                <option>Franklin Gothic Medium</option>
                                <option>Garamond</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Gill Sans</option>
                                <option>Impact</option>
                                <option>Lucida Sans</option>
                                <option>Sans Serif</option>
                                <option>Segoe UI</option>
                                <option>Times New Roman</option>
                                <option>Trebuchet MS</option>
                                <option>Verdana</option>
                            </Form.Control>
                        </Col>
                        <Form.Label className="label-text" column sm="3">
                            Card Text 2 Font:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                    oncardSimpleText2FontChange(e);
                                }} as="select">
                                <option>Arial Narrow</option>
                                <option>Arial Round</option>
                                <option>Calibri</option>
                                <option>Cambria</option>
                                <option>Courier</option>
                                <option>Franklin Gothic Medium</option>
                                <option>Garamond</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Gill Sans</option>
                                <option>Impact</option>
                                <option>Lucida Sans</option>
                                <option>Sans Serif</option>
                                <option>Segoe UI</option>
                                <option>Times New Roman</option>
                                <option>Trebuchet MS</option>
                                <option>Verdana</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>  
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Card Text 1 Font Weight:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue={cardSimpleText1FontWeight} onChange={(e)=>{onCardSimpleText1FontWeightChange(e)}} as="select">
                                <option>Normal</option>
                                <option>Bold</option>
                                <option>Extra-Bold</option>
                            </Form.Control>
                        </Col>
                        <Form.Label className="label-text" column sm="3">
                            Card Text 2 Font Weight:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue={cardSimpleText2FontWeight} onChange={(e)=>{onCardSimpleText2FontWeightChange(e)}} as="select">
                                <option>Normal</option>
                                <option>Bold</option>
                                <option>Extra-Bold</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Button Text:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <Form.Control defaultValue={cardSimpleButtonText} onChange={(e)=>{
                                    onCardSimpleButtonTextChange(e);
                                }}/>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onCardSimpleButtonTextColorChange(e)}}
                                    defaultValue={cardSimpleButtonTextColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="3">
                            Button Background Color:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onCardSimpleButtonBackgroundColorChange(e)}}
                                    defaultValue={cardSimpleButtonBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Button Font:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                    oncardSimpleButtonFontChange(e);
                                }} as="select">
                                <option>Arial Narrow</option>
                                <option>Arial Round</option>
                                <option>Calibri</option>
                                <option>Cambria</option>
                                <option>Courier</option>
                                <option>Franklin Gothic Medium</option>
                                <option>Garamond</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Gill Sans</option>
                                <option>Impact</option>
                                <option>Lucida Sans</option>
                                <option>Sans Serif</option>
                                <option>Segoe UI</option>
                                <option>Times New Roman</option>
                                <option>Trebuchet MS</option>
                                <option>Verdana</option>
                            </Form.Control>
                        </Col>
                        <Form.Label className="label-text" column sm="3">
                            Button Font Weight:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue={cardSimpleButtonFontWeight} onChange={(e)=>{onCardSimpleButtonFontWeightChange(e)}} as="select">
                                <option>Normal</option>
                                <option>Bold</option>
                                <option>Extra-Bold</option>
                            </Form.Control>
                        </Col>

                    </Form.Group>                  
                </Form>
                <Row>
                    <Col>
                        <div  className="editor-container border border-info">
                            <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                                <Tab eventKey="jsx" title="JSX">
                                    <ControlledEditor
                                        key="jsx"
                                        value={jsx}
                                        options={{
                                            lineWrapping:true,
                                            lint:true,
                                            lineNumbers:true,
                                            theme:"dracula",
                                            mode:"jsx",
                                            readOnly:true
                                        }}
                                    /> 
                                </Tab>
                                <Tab eventKey="css" title="CSS">
                                    <ControlledEditor
                                    key="css"
                                    value={css}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"css",
                                        readOnly:true
                                    }}
                                />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border border-info how-to-container">
                            <span className="how-to-title">How to Use</span>
                            <ol className="">
                                <li>
                                    Create a jsx file called <strong>card-simple.jsx</strong> and a css file called <strong>card-simple.css</strong> in your react-app.
                                </li>
                                <li>
                                    Copy and paste the code above in to a jsx file and a css file.
                                </li>
                                <li>
                                    Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                                </li>
                                <li>
                                    Add the line: import CardSimple from "./card-simple.jsx"; to App.js or any jsx file
                                </li>
                                <li>
                                    Import {"<CardSimple/>"} in to your App.js file
                                </li>
                                <li>
                                    Run your react app
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CardSimple;