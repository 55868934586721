import React, {useEffect, useState} from "react";
import {Card, Badge} from "react-bootstrap";
import "./components-list.styles.scss";
import { withRouter, Redirect } from 'react-router-dom'


const ComponentsList = ({history,subscriptionRole})=> {
	
	const paginationElements = ["#", "A","B","C","D","F","G","H","I"];


	useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

	

	const [currentPage, setCurrentPage] = useState("#");
	const [cards,setCards] = useState("");

	const  paginate = (e)=>{
		switch(e.target.innerText){
			case "#":
				setCurrentPage("#");
				
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/404-simple-page")}} className="card-text">
								404 - Simple Page
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img onClick={()=>{history.push("/404-simple-page")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/#/404-simple-page.png").default} />
					</Card>
					
					<Card className="card-component-list border border-info">
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/404-page-party-design")}} className="card-text">
								404 Page - Party Design
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img onClick={()=>{history.push("/404-page-party-design")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/#/404-party-design-cl.PNG").default}/>
					</Card>
				</>
				);
			case "A":
				setCurrentPage("A");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/accordian-simple")}} className="card-text">
								Accordian - Simple
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img onClick={()=>{history.push("/accordian-simple")}}  className="img" id="img-accordian" variant="bottom" src={require("../../assets/images/component-list/A/accordian-simple.PNG").default} />
					</Card>
					
				</>
				);
			case "B":
				setCurrentPage("B");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/badge-simple")}} className="card-text">
								Badge - Simple
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img onClick={()=>{history.push("/badge-simple")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/B/badge-simple.PNG").default} />
					</Card>
					
				</>
				);
			case "C":
				setCurrentPage("C");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/card-simple")}} className="card-text">
								Card - Simple
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img onClick={()=>{history.push("/card-simple")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/C/card-simple.PNG").default} />
					</Card>
					
				</>
				);
			case "D":
				setCurrentPage("D");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/divider-simple")}} className="card-text">
								Card - Simple
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img onClick={()=>{history.push("/divider-simple")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/D/divider-simple.PNG").default} />
					</Card>
					
				</>
				);
			case "E":
				setCurrentPage("E");
				return;
			case "F":
				setCurrentPage("F");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/form-simple")}} className="card-text">
								Form - Simple
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img id="img-form-simple" onClick={()=>{history.push("/form-simple")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/F/form-simple.PNG").default} />
					</Card>
					
				</>
				);	
			case "G":
				setCurrentPage("G");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/gallery-simple")}} className="card-text">
								Gallery - Simple
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img id="img-gallery-simple"  onClick={()=>{history.push("/gallery-simple")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/G/gallery-simple.PNG").default} />
					</Card>
					
				</>
				);
			case "H":
				setCurrentPage("H");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/header-simple")}} className="card-text">
								Header - Simple
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img id="img-header-simple" onClick={()=>{history.push("/header-simple")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/H/header-simple.PNG").default} />
					</Card>
					
				</>
				);
			case "I":
				setCurrentPage("I");
				return(<>
					<Card className="card-component-list border border-info">
						
						<Card.Body>
						<div className="component-list-title-container">
							<Card.Text onClick={()=>{history.push("/image-text-block")}} className="card-text">
								Image - Text Block
							</Card.Text>
						</div>
						</Card.Body>
						<Card.Img id="img-image-text-block" onClick={()=>{history.push("/image-text-block")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/I/image-text-block.PNG").default} />
					</Card>
					
				</>
				);
			case "J":
				setCurrentPage("J");
				return;
			case "K":
				setCurrentPage("K");
				return;
			case "L":
				setCurrentPage("L");
				return;
			case "M":
				setCurrentPage("M");
				return;
			case "N":
				setCurrentPage("N");
				return;
			case "O":
				setCurrentPage("O");
				return;
			case "P":
				setCurrentPage("P");
				return;
			case "Q":
				setCurrentPage("Q");
				return;
			case "R":
				setCurrentPage("R");
				return;
			case "S":
				setCurrentPage("S");
				return;	
			case "T":
				setCurrentPage("T");
				return;
			case "U":
				setCurrentPage("U");
				return;
			case "V":
				setCurrentPage("V");
				return;
			case "W":
				setCurrentPage("W");
				return;
			case "X":
				setCurrentPage("X");
				return;
			case "Y":
				setCurrentPage("Y");
				return;
			case "Z":
				setCurrentPage("Z");
				return;	
			default:
				console.log("Ok");
				return;
		}
	}


	return (
		<div>
			<nav className="pagination-styles ">
				<ul className="pagination flex-wrap ">
				
					{
						paginationElements.map((element)=>{
							return <li key={element} onClick={(e)=>{setCards(paginate(e))}} className="pagination-item page-item"><span  className={`page-link ${currentPage===element?"item-selected":""}`}>{element}</span></li>
						})
					}
				</ul>
				{
					cards === "" ?
					<>
						<Card className="card-component-list border border-info">
							
							<Card.Body>
							<div className="component-list-title-container">
								<Card.Text onClick={()=>{history.push("/404-simple-page")}} className="card-text">
									404 - Simple Page
								</Card.Text>
							</div>
							</Card.Body>
							<Card.Img onClick={()=>{history.push("/404-simple-page")}}  className="img" variant="bottom"src={require("../../assets/images/component-list/#/404-simple-page.png").default} />
						</Card>
						<Card className="card-component-list border border-info">
						
						<Card.Body>
							<div className="component-list-title-container">
								<Card.Text onClick={()=>{history.push("/404-page-party-design")}} className="card-text">
									404 Page - Party Design
								</Card.Text>
							</div>
							</Card.Body>
							<Card.Img onClick={()=>{ history.push("/404-page-party-design")}}  className="img" variant="bottom" src={require("../../assets/images/component-list/#/404-party-design-cl.PNG").default}/>
						</Card>
						
					</>
					: cards
				}
			</nav>
		</div>
	)
}

export default withRouter(ComponentsList);