import React, {useState} from "react";

import "./divider-shapes-preview.styles.scss";

const DividerShapesPreview = ()=>{
    return(
        <div>
            <hr class="divider"/>
        </div>      
    );
}

export default DividerShapesPreview;