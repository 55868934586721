import React from "react";

import "./card-simple-preview.styles.scss";

const CardSimplePreview = ()=>{
    return(
        <div className="card-simple-column">
            <div className="card-simple">
                <span className="card-simple-title">Card Titleasdsad</span>
                <p className="card-simple-text card-simple-text-1">Some text.asdasd</p>
                <p className="card-simple-text">Some text.asdasd</p>
                <a href="https://www.bing.com/"><button className="card-simple-button">Reassd More</button></a>
            </div>
        </div>
    );
}

export default CardSimplePreview;