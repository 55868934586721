import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./gallery-simple.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";

import galleryImage1 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-1.jpg";
import galleryImage2 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-2.jpg";
import galleryImage3 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-3.jpg";
import galleryImage4 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-4.jpg";

const GallerySimple = () =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [tabKey,setTabKey] = useState("jsx");

    const [gallerySimpleImage, setGallerySimpleImage] = useState(galleryImage1);

    const onChangeGallerySimple = (e)=>{
        switch(e.target.name){
            case "image1":
                setGallerySimpleImage(galleryImage1);
                return;
            case "image2":
                setGallerySimpleImage(galleryImage2);
                return;
            case "image3":
                setGallerySimpleImage(galleryImage3);
                return;
            case "image4":
                setGallerySimpleImage(galleryImage4);
                return;
            default:
                return;
        }
    }

    const [gallerySimpleBorderColor, setGallerySimpleBorderColor] = useState("#fff");
    
    const  onGallerySimpleBorderColorChange = (e) =>{
        setTimeout(()=>{
            setGallerySimpleBorderColor(e.target.value);
        },1000);
    }

    const [gallerySimpleSize,setGallerySimpleSize]= useState("600px");

    const onGallerySimpleSizeChange = (e) =>{
        switch(e.target.value){
            case "Small":
                setGallerySimpleSize("400px");
                return;
            case "Medium":
                setGallerySimpleSize("500px");
                return;
            case "Large":
                setGallerySimpleSize("600px");
                return;
            default:
                return;
        }
    }

    const [jsx, setJsx] = useState("");

    useEffect(()=>{
        setJsx(`import React, {useState} from "react";

import galleryImage1 from "../gallery-simple-sample-image-1.jpg";
import galleryImage2 from "../gallery-simple-sample-image-2.jpg";
import galleryImage3 from "../gallery-simple-sample-image-3.jpg";
import galleryImage4 from "../gallery-simple-sample-image-4.jpg";

import "./gallery-simple.styles.scss";

const GallerySimple = () =>{

    const [gallerySimpleImage, setGallerySimpleImage] = useState(galleryImage1);

    const onChangeGallerySimple = (e)=>{
        switch(e.target.name){
            case "image1":
                setGallerySimpleImage(galleryImage1);
                return;
            case "image2":
                setGallerySimpleImage(galleryImage2);
                return;
            case "image3":
                setGallerySimpleImage(galleryImage3);
                return;
            case "image4":
                setGallerySimpleImage(galleryImage4);
                return;
            default:
                return;
        }
    }

    return(
        <div className="gallery-simple">
            <img className="gallery-simple-main-image"  src={gallerySimpleImage} alt=""/>
            <div className="gallery-simple-thumbnails">
                <img onClick={onChangeGallerySimple} name="image1" className="gallery-simple-thumbnails-images" src={galleryImage1} alt=""/>
                <img onClick={onChangeGallerySimple} name="image2" className="gallery-simple-thumbnails-images" src={galleryImage2} alt=""/>
                <img onClick={onChangeGallerySimple} name="image3" className="gallery-simple-thumbnails-images" src={galleryImage3} alt=""/>
                <img onClick={onChangeGallerySimple} name="image4" className="gallery-simple-thumbnails-images" src={galleryImage4} alt=""/>
            </div>
        </div>            
    );
}

export default GallerySimple;`);
    },[]);

    const [css, setCss] = useState("");

    useEffect(()=>{
        setCss(`.gallery-simple{
    max-width:${gallerySimpleSize};
    margin:auto;
    background-color: #fff;
}

.gallery-simple-main-image{
    object-fit:cover;
    width:100%;
    border: ${gallerySimpleBorderColor} solid 3px;
}

.gallery-simple-thumbnails{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.gallery-simple-thumbnails-images{
    object-fit:cover;
    width:100%;
    cursor:pointer;
    border: ${gallerySimpleBorderColor} solid 3px;
}`);
    },[gallerySimpleSize,gallerySimpleBorderColor]);

    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            <p className="title-text">GALLERY-SIMPLE GENERATOR</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="accordian-simple-preview-pane">
                            <div className="accordian-simple-inner-container">
                                <div style={{maxWidth:gallerySimpleSize}} className="gallery-simple">
                                    <img style={{borderColor:gallerySimpleBorderColor}} className="gallery-simple-main-image"  src={gallerySimpleImage} alt=""/>
                                    <div className="gallery-simple-thumbnails">
                                        <img style={{borderColor:gallerySimpleBorderColor}}  onClick={onChangeGallerySimple} name="image1" className="gallery-simple-thumbnails-images" src={galleryImage1} alt=""/>
                                        <img style={{borderColor:gallerySimpleBorderColor}}  onClick={onChangeGallerySimple} name="image2" className="gallery-simple-thumbnails-images" src={galleryImage2} alt=""/>
                                        <img style={{borderColor:gallerySimpleBorderColor}}  onClick={onChangeGallerySimple} name="image3" className="gallery-simple-thumbnails-images" src={galleryImage3} alt=""/>
                                        <img style={{borderColor:gallerySimpleBorderColor}}  onClick={onChangeGallerySimple} name="image4" className="gallery-simple-thumbnails-images" src={galleryImage4} alt=""/>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </Col>
                </Row>
                <Form className="editor-container border border-info">
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="3">
                            Background Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onGallerySimpleBorderColorChange(e)}}
                                    defaultValue={gallerySimpleBorderColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Gallery Size:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Large" onChange={(e)=>{
                                    onGallerySimpleSizeChange(e);
                                }} as="select">
                                <option>Large</option>
                                <option>Medium</option>
                                <option>Small</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
                <Row>
                    <Col>
                        <div  className="editor-container border border-info">
                            <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                                <Tab eventKey="jsx" title="JSX">
                                    <ControlledEditor
                                        key="jsx"
                                        value={jsx}
                                        options={{
                                            lineWrapping:true,
                                            lint:true,
                                            lineNumbers:true,
                                            theme:"dracula",
                                            mode:"jsx",
                                            readOnly:true
                                        }}
                                    /> 
                                </Tab>
                                <Tab eventKey="css" title="CSS">
                                    <ControlledEditor
                                    key="css"
                                    value={css}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"css",
                                        readOnly:true
                                    }}
                                />
                                </Tab>    
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                <Row>
                <Col>
                    <div className="border border-info how-to-container">
                        <span className="how-to-title">How to Use</span>
                        <ol className="">
                            <li>
                                Create a jsx file called <strong>gallery-simple.jsx</strong> and a css file called <strong>gallery-simple.css</strong> in your react-app.
                            </li>
                            <li>
                                Copy and paste the code above in to a jsx file and a css file.
                            </li>
                            <li>
                                Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                            </li>
                            <li>
                                Add the line: import AccordianSimple from "./gallery-simple.jsx"; to App.js or any jsx file
                            </li>
                            <li>
                                Import {"<GallerySimple/>"} in to your App.js file
                            </li>
                            <li>
                                Change import image links to your files.
                            </li>
                            <li>
                                Run your react app
                            </li>
                        </ol>
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default GallerySimple;