import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";
import "./header-simple.styles.scss";

const HeaderSimple = () =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [tabKey,setTabKey] = useState("jsx");
    const [focus1, setFocus1] = useState(false);
    const [focus2, setFocus2] = useState(false);
    const [focus3, setFocus3] = useState(false);
    const [focus4, setFocus4] = useState(false);
    const [focus5, setFocus5] = useState(false);


    const [headerSimpleBackgroundColor,setHeaderSimpleBackgroundColor] = useState("#fff");

    const onHeaderSimpleBackgroundColorChange = (e) =>{
        setTimeout(()=>{
            setHeaderSimpleBackgroundColor(e.target.value);
        }, 1000);
    }

    const [headerSimpleFontColor,setHeaderSimpleFontColor] = useState("#33b5e5");

    const onHeaderSimpleFontColorChange = (e) =>{
        setTimeout(()=>{
            setHeaderSimpleFontColor(e.target.value);
        }, 1000);
    }

    const [headerSimpleHoverColor,setHeaderSimpleHoverColor] = useState("#ff0000");

    const onHeaderSimpleHoverColorChange = (e) =>{
        setTimeout(()=>{
            setHeaderSimpleHoverColor(e.target.value);
        }, 1000);
    }

    const [headerSimpleFont, setHeaderSimpleFont] = useState("Sans-Serif");

    const onHeaderSimpleChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setHeaderSimpleFont("Arial Narrow");
                return;
            case "Arial Round":
                setHeaderSimpleFont("Arial Round");
                return;
            case "Calibri":
                setHeaderSimpleFont("Calibri");
                return;
            case "Cambria":
                setHeaderSimpleFont("Cambria");
                return;
            case "Courier":
                setHeaderSimpleFont("Courier");
                return;
            case "Franklin Gothic Medium":
                setHeaderSimpleFont("Franklin Gothic Medium");
                return;
            case "Garamond":
                setHeaderSimpleFont("Garamond");
                return;
            case "Geneva":
                setHeaderSimpleFont("Geneva");
                return;
            case "Georgia":
                setHeaderSimpleFont("Georgia");
                return;
            case "Gill Sans":
                setHeaderSimpleFont("Gill Sans");
                return;
            case "Impact":
                setHeaderSimpleFont("Impact");
                return;
            case "Lucida Sans":
                setHeaderSimpleFont("Lucida Sans");
                return;
            case "Sans Serif":
                setHeaderSimpleFont("sans-serif");
                return;
            case "Segoe UI":
                setHeaderSimpleFont("Segoe UI");
                return;
            case "Times New Roman":
                setHeaderSimpleFont("Times New Roman");
                return;
            case "Trebuchet MS":
                setHeaderSimpleFont("Trebuchet MS");
                return;
            case "Verdana":
                setHeaderSimpleFont("Verdana");
                return;
            default:
                return;    
        }
    }

    const [jsx, setJsx] = useState("");
    const [css, setCss] = useState("");

    useEffect(()=>{
        setJsx(`import React from "react";

import "./header-simple.styles.css";

const HeaderSimple = () =>{
    return(
        <header className="header-simple">
            <a className="header-simple-logo" href="#">Logo</a>
            <ul className="header-simple-nav">
                <li className="header-simple-items"><a className="header-simple-text" href="#">Home</a></li>
                <li className="header-simple-items"><a className="header-simple-text" href="#">Shop</a></li>
                <li className="header-simple-items"><a className="header-simple-text" href="#">About Us</a></li>
                <li className="header-simple-items"><a className="header-simple-text" href="#">Contact</a></li>
            </ul>
        </header>
    );
}

export default HeaderSimple;`);
    },[]);

    useEffect(()=>{
        setCss(`.header-simple{
    margin:0;
    padding:0;
    box-sizing: border-box;
    width:100%;
    background:${headerSimpleBackgroundColor};
    display:flex;
    gap:5%;
    align-items:center;
}

.header-simple-nav{
    padding: 0px;
    margin: 0px;
    text-align: center;
    display:inline-block;
    vertical-align:top;
}

.header-simple-logo{
    margin:0;
    padding:0;
    box-sizing: border-box;
    text-decoration:none;
    color:${headerSimpleFontColor};
    font-size:2.188rem;
    margin-left:2.5rem;
    font-family:${headerSimpleFont};
} 

.header-simple-logo:hover{
    box-sizing: border-box;
    text-decoration:none;
    color:${headerSimpleHoverColor};
    transition:.3s ease-in;
}


.header-simple-items{
    margin:0;
    padding:0;
    box-sizing: border-box;
    display:inline-block;
    margin-right:2.188rem;
    list-style:none;
}

.header-simple-text{
    margin:0;
    padding:0;
    box-sizing: border-box;
    text-decoration:none;
    color:${headerSimpleFontColor};
    font-size:1.25rem;
    font-family:${headerSimpleFont};
}

.header-simple-text:hover{
    box-sizing: border-box;
    text-decoration:none;
    color:${headerSimpleHoverColor};
    transition:.3s ease-in;
}

@media only screen and (max-width: 584px) {
    .header-simple{
        flex-direction:column;
        gap:0;
    }
    .header-simple-nav{
        display:flex;
        flex-direction:column;
        align-items:center;
        width:100%;
    }

    .header-simple-items{
        margin:0;
    }

    .header-simple-logo{
        margin:0;
    }
}`);
    },[headerSimpleBackgroundColor,headerSimpleFontColor,headerSimpleHoverColor,headerSimpleFont]);

    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            <p className="title-text">HEADER-SIMPLE GENERATOR</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="accordian-simple-preview-pane">
                            <div className="accordian-simple-inner-container">
                                <header style={{backgroundColor:headerSimpleBackgroundColor}} className="header-simple">
                                    <a onMouseEnter={() => setFocus1(true)} onMouseLeave={() => setFocus1(false)} style={{color:focus1?headerSimpleHoverColor:headerSimpleFontColor,fontFamily:headerSimpleFont}} className={"header-simple-logo"} href="https://www.google.com/">Logo</a>
                                    <ul className="header-simple-nav">
                                        <li className="header-simple-items"><a onMouseEnter={() => setFocus2(true)} onMouseLeave={() => setFocus2(false)} style={{color:focus2?headerSimpleHoverColor:headerSimpleFontColor,fontFamily:headerSimpleFont}} className="header-simple-text"  href="https://www.google.com/">Home</a></li>
                                        <li className="header-simple-items"><a onMouseEnter={() => setFocus3(true)} onMouseLeave={() => setFocus3(false)} style={{color:focus3?headerSimpleHoverColor:headerSimpleFontColor,fontFamily:headerSimpleFont}} className="header-simple-text" href="https://www.google.com/">Shop</a></li>
                                        <li className="header-simple-items"><a onMouseEnter={() => setFocus4(true)} onMouseLeave={() => setFocus4(false)} style={{color:focus4?headerSimpleHoverColor:headerSimpleFontColor,fontFamily:headerSimpleFont}} className="header-simple-text" href="https://www.google.com/">About Us</a></li>
                                        <li className="header-simple-items"><a onMouseEnter={() => setFocus5(true)} onMouseLeave={() => setFocus5(false)} style={{color:focus5?headerSimpleHoverColor:headerSimpleFontColor,fontFamily:headerSimpleFont}} className="header-simple-text" href="https://www.google.com/">Contact</a></li>
                                    </ul>
                                </header>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Form className="editor-container border border-info">
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Background Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onHeaderSimpleBackgroundColorChange(e)}}
                                    defaultValue={headerSimpleBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Font Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onHeaderSimpleFontColorChange(e)}}
                                    defaultValue={headerSimpleFontColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Hover Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onHeaderSimpleHoverColorChange(e)}}
                                    defaultValue={headerSimpleHoverColor}
                                /></span>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Text Font:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                    onHeaderSimpleChange(e);
                                }} as="select">
                                <option>Arial Narrow</option>
                                <option>Arial Round</option>
                                <option>Calibri</option>
                                <option>Cambria</option>
                                <option>Courier</option>
                                <option>Franklin Gothic Medium</option>
                                <option>Garamond</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Gill Sans</option>
                                <option>Impact</option>
                                <option>Lucida Sans</option>
                                <option>Sans Serif</option>
                                <option>Segoe UI</option>
                                <option>Times New Roman</option>
                                <option>Trebuchet MS</option>
                                <option>Verdana</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
                <Row>
                    <Col>
                        <div  className="editor-container border border-info">
                            <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                                <Tab eventKey="jsx" title="JSX">
                                    <ControlledEditor
                                        key="jsx"
                                        value={jsx}
                                        options={{
                                            lineWrapping:true,
                                            lint:true,
                                            lineNumbers:true,
                                            theme:"dracula",
                                            mode:"jsx",
                                            readOnly:true
                                        }}
                                    /> 
                                </Tab>
                                <Tab eventKey="css" title="CSS">
                                    <ControlledEditor
                                    key="css"
                                    value={css}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"css",
                                        readOnly:true
                                    }}
                                />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border border-info how-to-container">
                            <span className="how-to-title">How to Use</span>
                            <ol className="">
                                <li>
                                    Create a jsx file called <strong>header-simple.jsx</strong> and a css file called <strong>header-simple.css</strong> in your react-app.
                                </li>
                                <li>
                                    Copy and paste the code above in to a jsx file and a css file.
                                </li>
                                <li>
                                    Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                                </li>
                                <li>
                                    Add the line: import HeaderSimple from "./header-simple.jsx"; to App.js or any jsx file
                                </li>
                                <li>
                                    Import {"<HeaderSimple/>"} in to your App.js file
                                </li>
                                <li>
                                    Run your react app
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default HeaderSimple;