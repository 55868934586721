import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./divider-shapes.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";

const DividerShapes = () =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [tabKey,setTabKey] = useState("jsx");

    const [dividerSimpleColor, setDividerSimpleColor] = useState("#bbb");

    const onDividerSimpleColorChange = (e)=>{
        setTimeout(()=>{
            setDividerSimpleColor(e.target.value);
        },1000);
        return;
    }

    const [dividerSimpleSize, setDividerSimpleSize] = useState("0.188rem");

    const onDividerSimpleSizeChange = (e)=>{
        switch(e.target.value){    
            case "Large":
                setDividerSimpleSize("0.563rem");
                break;
            case "Medium":
                setDividerSimpleSize("0.375rem");
                break;
            case "Small":
                setDividerSimpleSize("0.188rem");
                break;
            case "Extra-SMall":
                setDividerSimpleSize("0.1rem");
                break;
            default:
                break;
        }

    }

    const [dividerSimpleType,setDividerSimpleType]=useState("dashed");

    const onDividerSimpleTypeChange = (e)=>{
        switch(e.target.value){
            case "Solid":
                setDividerSimpleType("Solid");
                break;
            case "Dotted":
                setDividerSimpleType("Dotted");
                break;
            case "Double":
                setDividerSimpleType("Double");
                break;
            case "Groove":
                setDividerSimpleType("Groove");
                break;
            case "Ridge":
                setDividerSimpleType("Ridge");
                break;
            case "Dashed":
                setDividerSimpleType("Dashed");
                break;      
            default:
                break;
        }
    }

    const [jsx, setJsx] = useState("");
    const [css, setCss] = useState("");    

    useEffect(()=>{
        setJsx(`import React, {useState} from "react";
import "./divider-simple.css";

const DividerSimple = ()=>{
    return(
        <div>
            <hr class="divider"/>
        </div>      
    );
}

export default DividerSimple;`);});

    useEffect(()=>{
        setCss(`.divider {
    border-top: 0.188rem Dashed #bbb;
}

.divider-simple-center-pane{
    padding-top:10%;
    padding-bottom:10%;
}`);});

    return(
        <>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            
                            <p className="title-text">DIVIDER-SIMPLE GENERATOR</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="accordian-simple-preview-pane">
                            <div className="accordian-simple-inner-container divider-simple-center-pane">
                                <hr style={{borderTop:`${dividerSimpleSize} ${dividerSimpleType} ${dividerSimpleColor}`}} class="divider"/>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Form className="editor-container border border-info">
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onDividerSimpleColorChange(e)}}
                                    defaultValue={dividerSimpleColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="1">
                            Size:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control  defaultValue="Small" onChange={(e)=>{
                                    onDividerSimpleSizeChange(e);
                                }} as="select">
                                <option>Large</option>
                                <option>Medium</option>
                                <option>Small</option>
                                <option>Extra-Small</option>
                            </Form.Control>   
                        </Col>
                        <Form.Label className="label-text" column sm="1">
                            Type:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control  defaultValue="Dashed" onChange={(e)=>{
                                    onDividerSimpleTypeChange(e);
                                }} as="select">
                                <option>Solid</option>
                                <option>Dotted</option>
                                <option>Double</option>
                                <option>Groove</option>
                                <option>Ridge</option>
                                <option>Dashed</option>
                            </Form.Control> 
                        </Col>
                    </Form.Group>
                </Form>
                <Row>
                    <Col>
                        <div  className="editor-container border border-info">
                            <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                                <Tab eventKey="jsx" title="JSX">
                                    <ControlledEditor
                                        key="jsx"
                                        value={jsx}
                                        options={{
                                            lineWrapping:true,
                                            lint:true,
                                            lineNumbers:true,
                                            theme:"dracula",
                                            mode:"jsx",
                                            readOnly:true
                                        }}
                                    /> 
                                </Tab>
                                <Tab eventKey="css" title="CSS">
                                    <ControlledEditor
                                    key="css"
                                    value={css}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"css",
                                        readOnly:true
                                    }}
                                />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border border-info how-to-container">
                            <span className="how-to-title">How to Use</span>
                            <ol className="">
                                <li>
                                    Create a jsx file called <strong>divider-simple.jsx</strong> and a css file called <strong>divider-simple.css</strong> in your react-app.
                                </li>
                                <li>
                                    Copy and paste the code above in to a jsx file and a css file.
                                </li>
                                <li>
                                    Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                                </li>
                                <li>
                                    Add the line: import DividerShapes from "./divider-simple.jsx"; to App.js or any jsx file
                                </li>
                                <li>
                                    Import {"<DividerSimple/>"} in to your App.js file or jsx file
                                </li>
                                <li>
                                    Run your react app
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DividerShapes;