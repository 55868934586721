import React from "react";
 
import "./404-page-party-design-preview.styles.scss";
import partyDesign404PageLogo from "../../../../assets/images/component-list/#/404-page-party-design.svg";

const PartyDesign404PagePreview = () =>{
    return(
        <div class="party-design-404-page">
            <img class="party-design-404-page-logo-image" src={partyDesign404PageLogo} alt="404 Page"/>
            <p className="party-design-404-page-title" >Page Not Found</p>
            <p className="party-design-404-page-text">Sorry can't find the page you are looking for click <a className="party-design-404-page-link" href="https://www.google.com/">Here</a> to go back to the home page.</p>
        </div>
    );
};

export default PartyDesign404PagePreview;
