import React from "react";

import "./image-text-block-preview.styles.scss";
import imageTextBlockStock from "../../../../assets/images/component-list/I/image-text-block-stock.jpg";

const ImageTextBlockPreview = () =>{
    return(
        <div class="image-text-block-container">
            <img src={imageTextBlockStock} alt="forest" className="image-text-block-stock"/>
            <div className="image-text-block-text-block">
                <p className="image-text-block-title">Nature</p>
                <p> What a beautiful forest</p>
            </div>
        </div>
    );
}

export default ImageTextBlockPreview;