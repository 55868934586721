import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./badge-simple.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";

const BadgeSimple = () =>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [tabKey,setTabKey] = useState("jsx");

    const [badgeSize,setBadgeSize] = useState("1.17rem");
    
    const onBadgeSizeChange = (e)=>{
        switch(e.target.value){
            case "Extra-Large":
                setBadgeSize("2rem");
                break;
            case "Large":
                setBadgeSize("1.5rem");
                break;
            case "Medium":
                setBadgeSize("1.17rem");
                break;
            case "Small":
                setBadgeSize("1rem");
                break;
            case "Extra-Small":
                setBadgeSize("0.83rem");
                break;
            default:
                break ;
                
        }
    }

    const [badgeFontColor,setBadgeFontColor] = useState("#FFFFFF");

    const onBadgeFontColorChange = (e)=>{
        setTimeout(()=>{
            setBadgeFontColor(e.target.value)
        },1000);  
    }

    const [textBadge,setTextBadge] = useState("Badge");

    const onTextBadgeChange = (e)=>{
        setTextBadge(e.target.value);
    }

    const [badgeBackgroundColor,setBadgeBackgroundColor] = useState("#FF0000");

    const onBadgeBackgroundColorChange = (e)=>{
        setTimeout(()=>{
            setBadgeBackgroundColor(e.target.value)
        },1000); 
    }

    const [badgeFontFamily,setBadgeFontFamily] = useState("sans-serif");

    const onBadgeFontFamily = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setBadgeFontFamily("Arial Narrow");
                return;
            case "Arial Round":
                setBadgeFontFamily("Arial Round");
                return;
            case "Calibri":
                setBadgeFontFamily("Calibri");
                return;
            case "Cambria":
                setBadgeFontFamily("Cambria");
                return;
            case "Courier":
                setBadgeFontFamily("Courier");
                return;
            case "Franklin Gothic Medium":
                setBadgeFontFamily("Franklin Gothic Medium");
                return;
            case "Garamond":
                setBadgeFontFamily("Garamond");
                return;
            case "Geneva":
                setBadgeFontFamily("Geneva");
                return;
            case "Georgia":
                setBadgeFontFamily("Georgia");
                return;
            case "Gill Sans":
                setBadgeFontFamily("Gill Sans");
                return;
            case "Impact":
                setBadgeFontFamily("Impact");
                return;
            case "Lucida Sans":
                setBadgeFontFamily("Lucida Sans");
                return;
            case "Sans Serif":
                setBadgeFontFamily("sans-serif");
                return;
            case "Segoe UI":
                setBadgeFontFamily("Segoe UI");
                return;
            case "Times New Roman":
                setBadgeFontFamily("Times New Roman");
                return;
            case "Trebuchet MS":
                setBadgeFontFamily("Trebuchet MS");
                return;
            case "Verdana":
                setBadgeFontFamily("Verdana");
                return;
            default:
                return;    
        }
    }

    const [badgeFontWeight,setBadgeFontWeight] = useState(400);

    const onBadgeFontWeightChange = (e)=>{
        switch(e.target.value){
            case "Normal":
                setBadgeFontWeight(400);
                break;
            case "Bold":
                setBadgeFontWeight(600);
                break;
            case "Extra-Bold":
                setBadgeFontWeight(800);
                break;
            default:
                break ;
                
        }
    }

    
    const [jsx, setJsx] = useState("");
    const [css, setCss] = useState("");

    useEffect(()=>{
        setJsx(`import React, {useState} from "react";
import "./badge-simple.styles.scss";

const BadgeSimple = () =>{
    return(
        <span className="badge">${textBadge}</span> 
    );
}

export default BadgeSimple;`);
    },[textBadge]);

    useEffect(()=>{
        setCss(`.badge {
    background-color: ${badgeBackgroundColor};
    color: ${badgeFontColor};
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    font-size:${badgeSize};
    font-family:${badgeFontFamily};
    font-weight:${badgeFontWeight};
}`);
    },[badgeBackgroundColor,badgeFontColor,badgeSize,badgeFontFamily,badgeFontWeight]);

    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            
                            <p className="title-text">BADGE-SIMPLE GENERATOR</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="accordian-simple-preview-pane">
                            <div className="inner-container-center accordian-simple-inner-container badge-simple-padding">
                                <span style={{fontWeight:badgeFontWeight,fontFamily:badgeFontFamily,color:badgeFontColor,fontSize:badgeSize,backgroundColor:badgeBackgroundColor}} className="badge-simple">{textBadge}</span>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Form className="editor-container border border-info">

                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Badge size:
                        </Form.Label>

                        <Col sm="3">
                            <Form.Control  defaultValue="Medium" onChange={(e)=>{
                                    onBadgeSizeChange(e);
                                }} as="select">
                                <option>Extra-Large</option>
                                <option>Large</option>
                                <option>Medium</option>
                                <option>Small</option>
                                <option>Extra-Small</option>
                            </Form.Control>   
                        </Col>

                        <Form.Label className="label-text" column sm="2">
                            Font Color:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onBadgeFontColorChange(e)}}
                                    defaultValue={badgeFontColor}
                                /></span>
                            </InputGroup>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row} className="">

                        <Form.Label className="label-text" column sm="2">
                            Badge Text:
                        </Form.Label>
                        <Col sm="3">
                            <InputGroup>
                                <Form.Control defaultValue={textBadge} onChange={(e)=>{
                                    onTextBadgeChange(e);
                                }} />
                            </InputGroup>
                        </Col>

                        <Form.Label className="label-text" column sm="2">
                            Background Color:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onBadgeBackgroundColorChange(e)}}
                                    defaultValue={badgeBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>

                    </Form.Group>
                    
                    <Form.Group as={Row} className="">
                        <Form.Label className="label-text" column sm="2">
                            Font Family:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                    onBadgeFontFamily(e);
                                }} as="select">
                                <option>Arial Narrow</option>
                                <option>Arial Round</option>
                                <option>Calibri</option>
                                <option>Cambria</option>
                                <option>Courier</option>
                                <option>Franklin Gothic Medium</option>
                                <option>Garamond</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Gill Sans</option>
                                <option>Impact</option>
                                <option>Lucida Sans</option>
                                <option>Sans Serif</option>
                                <option>Segoe UI</option>
                                <option>Times New Roman</option>
                                <option>Trebuchet MS</option>
                                <option>Verdana</option>
                            </Form.Control>
                        </Col> 

                        <Form.Label className="label-text" column sm="2">
                            Font Weight:
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control defaultValue={badgeFontWeight} onChange={(e)=>{onBadgeFontWeightChange(e)}} as="select">
                                <option>Normal</option>
                                <option>Bold</option>
                                <option>Extra-Bold</option>
                            </Form.Control>
                        </Col>

                    </Form.Group>

                </Form>

                <Row>
                    <Col>
                        <div  className="editor-container border border-info">
                            <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                                <Tab eventKey="jsx" title="JSX">
                                    <ControlledEditor
                                        key="jsx"
                                        value={jsx}
                                        options={{
                                            lineWrapping:true,
                                            lint:true,
                                            lineNumbers:true,
                                            theme:"dracula",
                                            mode:"jsx",
                                            readOnly:true
                                        }}
                                    /> 
                                </Tab>
                                <Tab eventKey="css" title="CSS">
                                    <ControlledEditor
                                    key="css"
                                    value={css}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"css",
                                        readOnly:true
                                    }}
                                />
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="border border-info how-to-container">
                            <span className="how-to-title">How to Use</span>
                            <ol className="">
                                <li>
                                    Create a jsx file called <strong>badge-simple.jsx</strong> and a css file called <strong>badge-simple.css</strong> in your react-app.
                                </li>
                                <li>
                                    Copy and paste the code above in to a jsx file and a css file.
                                </li>
                                <li>
                                    Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                                </li>
                                <li>
                                    Import {"<BadgeSimple/>"} in to your App.js file
                                </li>
                                <li>
                                    Run your react app
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BadgeSimple;