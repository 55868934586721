import React, {useState} from "react";
import "./accordian-simple-preview.styles.scss";

const AccordianSimplePreview = ()=> {

    const [selected,setSelected] = useState(null);

    const toggle = (i)=>{
        if( selected === i){
            setSelected(null);
            return;
        }

        setSelected(i);
        return;
    }
    
    let data= [
        {
            category:"Category 1",
            content:"Content 1"
        },
        {
            category:"Category 2",
            content:"Content 2"
        },
        {
            category:"Category 3",
            content:"Content 3"
        },
        {
            category:"Category 4",
            content:"Content 4"
        }
    ];
    return (
        <div className="wrapper">
            <div className="container-accordian">
            {
                data.map((item,i)=>{
                    return <div key={i} className="accordian-items" >
                        <div className="category" onClick={() =>toggle(i)}>
                            {item.category}
                            <span>{selected === i ? "-" : "+"}</span>
                        </div>
                        <div className={selected === i ? "content-show" : "content"}>
                            {item.content}
                        </div>
                    </div>;
                })
            }
            </div>
        </div>
    );
}

export default AccordianSimplePreview;

