import React from "react";
import "./App.scss";
import { Container } from 'react-bootstrap';
import {Route, BrowserRouter, Switch, withRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

/*Imports for compnents*/
import Header from "./components/header/header.component";
import BackgroundParticles from "./components/background-particles/background-particles.component";
import Homepage from "./components/page-homepage/homepage.component";
import ComponentsList from "./components/page-components-list/components-list.component";
import Loader from "./components/loader/loader.component";
import Footer from  "./components/footer/footer.component";

/*Imports for component pages #*/
import Simple404Page from "./components/component-pages/#/404-simple-page/404-simple-page.component";
import Simple404PagePreview from "./components/component-pages/#/404-simple-page/404-simple-page-preview.component";

import PartyDesign404PagePreview from "./components/component-pages/#/404-page-design/404-page-party-design-preview.component";
import PartyDesign404Page from "./components/component-pages/#/404-page-design/404-page-party-design.component";

/*Imports for component pages A*/
import AccordianSimple from "./components/component-pages/A/accordian-simple/accordian-simple.component";
import AccordianSimplePreview from "./components/component-pages/A/accordian-simple/accordian-simple-preview.component";

/*Imports for component pages B*/
import BadgeSimplePreview from "./components/component-pages/B/badge-simple/badge-simple-preview.component";
import BadgeSimple from "./components/component-pages/B/badge-simple/badge-simple.component";

/*Imports for component pages C*/
import CardSimplePreview from "./components/component-pages/C/card-simple/card-simple-preview.component";
import CardSimple from "./components/component-pages/C/card-simple/card-simple.component";

/*Imports for component pages D*/
import DividerShapesPreview from "./components/component-pages/D/divider-shapes/divider-shapes-preview.component";
import DividerShapes from "./components/component-pages/D/divider-shapes/divider-shapes.component";

/*Imports for component pages F*/
import FormSimplePreview from "./components/component-pages/F/form-simple/form-simple-preview.component";
import FormSimple from "./components/component-pages/F/form-simple/form-simple.component";

/*Imports for component pages G*/
import GallerySimplePreview from "./components/component-pages/G/gallery-simple/gallery-simple-preview.component";
import GallerySimple from "./components/component-pages/G/gallery-simple/gallery-simple.component";

/*Imports for component pages H*/
import HeaderSimplePreview from "./components/component-pages/H/header-simple/header-simple-preview.component";
import HeaderSimple from "./components/component-pages/H/header-simple/header-simple.component";

/*Imports for component pages I*/
import ImageTextBlockPreview from "./components/component-pages/I/image-text-block/image-text-block-preview.component";
import ImageTextBlock from "./components/component-pages/I/image-text-block/image-text-block.component";

/*Firebase*/


class App extends React.Component{
	constructor(props){
		super(props);


		this.state = ({ 
			currentUser: null, 
			isLoading:true,
			isAuthenticated:false,
			isNotAuthenticated:true,
			subscriptionRole:false
		});  
	}
	
	
	
	componentDidMount(){

		setTimeout(()=>{
			this.setState({isLoading:false});
		},1000);
		
	}
	

	
	
	
	render(){
		if(this.state.isLoading)
		return(<Loader/>)
		if(!(this.props.location.pathname.includes("/preview")))
		return (			
					<BrowserRouter>
						<div>
							<BackgroundParticles/>
							<Container className="background">
								<Header />
								<Switch>
									<Route path="/" exact component={Homepage}/>
									<Route path="/components-list" exact component={ComponentsList}/>
						
									{/*Routes to component Pages #*/}
									<Route path="/404-simple-page" exact><Simple404Page/></Route>

									<Route path="/404-page-party-design" exact><PartyDesign404Page/></Route>
									
									{/*Routes to component Pages A*/}
									<Route path="/accordian-simple" exact><AccordianSimple/></Route>

									{/*Routes to component Pages B*/}
									<Route path="/badge-simple" exact><BadgeSimple/></Route>

									{/*Routes to component Pages C*/}
									<Route path="/card-simple" exact><CardSimple/></Route>

									{/*Routes to component Pages D*/}
									<Route path="/divider-simple" exact><DividerShapes/></Route>

									{/*Routes to component Pages F*/}
									<Route path="/form-simple" exact><FormSimple/></Route>

									{/*Routes to component Pages G*/}
									<Route path="/gallery-simple" exact><GallerySimple/></Route>

									{/*Routes to component Pages H*/}
									<Route path="/header-simple" exact><HeaderSimple/></Route>

									{/*Routes to component Pages I*/}
									<Route path="/image-text-block" exact><ImageTextBlock/></Route>
									
								</Switch>
							</Container>
							<Footer/>
						</div>
						
					</BrowserRouter>
				
		)
		return(
			<BrowserRouter>
				<div>
				{/*Routes to component Pages #*/}
				<Route path="/preview/404-simple-page-preview" exact><Simple404PagePreview/></Route>

				<Route path="/preview/404-page-party-design-preview" exact><PartyDesign404PagePreview/></Route>

				{/*Routes to component Pages A*/}
				<Route path="/preview/accordian-simple-preview" exact><AccordianSimplePreview/></Route>

				{/*Routes to component Pages B*/}
				<Route path="/preview/badge-simple-preview" exact><BadgeSimplePreview/></Route>

				{/*Routes to component Pages C*/}
				<Route path="/preview/card-simple-preview" exact><CardSimplePreview/></Route>

				{/*Routes to component Pages D*/}
				<Route path="/preview/divider-shapes-preview" exact><DividerShapesPreview/></Route>

				{/*Routes to component Pages F*/}
				<Route path="/preview/form-simple-preview" exact><FormSimplePreview/></Route>
				
				{/*Routes to component Pages G*/}
				<Route path="/preview/gallery-simple-preview" exact><GallerySimplePreview/></Route>

				{/*Routes to component Pages H*/}
				<Route path="/preview/header-simple-preview" exact><HeaderSimplePreview/></Route>

				{/*Routes to component Pages I*/}
				<Route path="/preview/image-text-block-preview" exact><ImageTextBlockPreview/></Route>

				</div>
			</BrowserRouter>
		)
	}
}
	
export default withRouter(App);
