import React from "react";
import "./footer.styles.scss";
import { useHistory } from "react-router";
import { Button} from 'react-bootstrap';

const Footer =()=>{
	const history = useHistory();
	
    return(
        <footer className="footer-section footer-distributed">

			<div className="footer-left">
				<p className="footer-links">
					<span className="link-1" onClick={()=>{window.scrollTo(0, 0); history.push("/");}}>Home</span>
					<span onClick={()=>{history.push("/components-list");}}>Component List</span>
					<a className="footer-contact"  href="https://twitter.com/ComponentReact"><span>Contact</span></a>
				</p>
				<p>React Component Generator &copy; 2023</p>
			</div>

		</footer>
    );
}

export default Footer;