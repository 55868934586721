import React, {useState} from "react";

import galleryImage1 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-1.jpg";
import galleryImage2 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-2.jpg";
import galleryImage3 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-3.jpg";
import galleryImage4 from "../../../../assets/images/component-list/G/gallery-simple-sample-image-4.jpg";

import "./gallery-simple-preview.styles.scss";

const GallerySimplePreview = () =>{

    const [gallerySimpleImage, setGallerySimpleImage] = useState(galleryImage1);

    const onChangeGallerySimple = (e)=>{
        switch(e.target.name){
            case "image1":
                setGallerySimpleImage(galleryImage1);
                return;
            case "image2":
                setGallerySimpleImage(galleryImage2);
                return;
            case "image3":
                setGallerySimpleImage(galleryImage3);
                return;
            case "image4":
                setGallerySimpleImage(galleryImage4);
                return;
            default:
                return;
        }
    }

    return(
        <div className="gallery-simple">
            <img className="gallery-simple-main-image"  src={gallerySimpleImage} alt=""/>
            <div className="gallery-simple-thumbnails">
                <img onClick={onChangeGallerySimple} name="image1" className="gallery-simple-thumbnails-images" src={galleryImage1} alt=""/>
                <img onClick={onChangeGallerySimple} name="image2" className="gallery-simple-thumbnails-images" src={galleryImage2} alt=""/>
                <img onClick={onChangeGallerySimple} name="image3" className="gallery-simple-thumbnails-images" src={galleryImage3} alt=""/>
                <img onClick={onChangeGallerySimple} name="image4" className="gallery-simple-thumbnails-images" src={galleryImage4} alt=""/>
            </div>
        </div>            
    );
}

export default GallerySimplePreview;