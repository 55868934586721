import React, {useState} from "react";

import "./header-simple-preview.styles.scss";

const HeaderSimplePreview = () =>{
    return(
        <header className="header-simple">
            <a className="header-simple-logo" href="https://www.google.com/">Logo</a>
            <ul className="header-simple-nav">
                <li className="header-simple-items"><a className="header-simple-text"  href="https://www.google.com/">Home</a></li>
                <li className="header-simple-items"><a className="header-simple-text" href="https://www.google.com/">Shop</a></li>
                <li className="header-simple-items"><a className="header-simple-text" href="https://www.google.com/">About Us</a></li>
                <li className="header-simple-items"><a className="header-simple-text" href="https://www.google.com/">Contact</a></li>
            </ul>
        </header>
    );
}

export default HeaderSimplePreview;