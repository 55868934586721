import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./form-simple.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";

const FormSimple = ()=>{

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [tabKey,setTabKey] = useState("jsx");

    const [formSimpleBackgroundColor,setFormSimpleBackgroundColor] = useState("#15172b");

    const onFormSimpleBackgroundColor = (e)=>{
        setTimeout(()=>{
            setFormSimpleBackgroundColor(e.target.value)
        },1000); 
    }

    const [formSimpleFormBackgroundColor,setFormSimpleFormBackgroundColor] = useState("#303245");

    const onFormSimpleFormBackgroundColor = (e)=>{
        setTimeout(()=>{
            setFormSimpleFormBackgroundColor(e.target.value)
        },1000); 
    }

    const [formSimpleButtonBackgroundColor,setFormSimpleButtonBackgroundColor] = useState("#08d");

    const onFormSimpleButtonBackgroundColor = (e)=>{
        setTimeout(()=>{
            setFormSimpleButtonBackgroundColor(e.target.value)
        },1000); 
    }

    const [formSimpleTitleText, setFormSimpleTitleText] = useState("Welcome");

    const  onFormSimpleTitleText = (e) =>{
        setFormSimpleTitleText(e.target.value);
    }

    const [formSimpleTitleColor, setFormSimpleTitleColor] = useState("#eee");

    const  onFormSimpleTitleColorChange = (e) =>{
        setTimeout(()=>{
            setFormSimpleTitleColor(e.target.value);
        },1000);
    }

    const [formSimpleSubTitleText, setFormSimpleSubTitleText] = useState("Let's Create your account!");

    const  onFormSimpleSubTitleText = (e) =>{
        setFormSimpleSubTitleText(e.target.value);
    }

    const [formSimpleSubTitleColor, setFormSimpleSubTitleColor] = useState("#eee");

    const  onFormSimpleSubTitleColorChange = (e) =>{
        setTimeout(()=>{
            setFormSimpleSubTitleColor(e.target.value);
        },1000);
    }

    const [formSimpleInput1Text, setFormSimpleInput1Text] = useState("First Name");

    const  onFormSimpleInput1TextChange = (e) =>{
        setFormSimpleInput1Text(e.target.value);
    }

    const [formSimpleInput1Color, setFormSimpleInput1Color] = useState("#eee");

    const  onFormSimpleInput1ColorChange = (e) =>{
        setTimeout(()=>{
            setFormSimpleInput1Color(e.target.value);
        },1000);
    }

    const [formSimpleInput2Text, setFormSimpleInput2Text] = useState("Last Name");

    const  onFormSimpleInput2TextChange = (e) =>{
        setFormSimpleInput2Text(e.target.value);
    }

    const [formSimpleInput2Color, setFormSimpleInput2Color] = useState("#eee");

    const  onFormSimpleInput2ColorChange = (e) =>{
        setTimeout(()=>{
            setFormSimpleInput2Color(e.target.value);
        },1000);
    }

    const [formSimpleInput3Text, setFormSimpleInput3Text] = useState("Email");

    const  onFormSimpleInput3TextChange = (e) =>{
        setFormSimpleInput3Text(e.target.value);
    }

    const [formSimpleInput3Color, setFormSimpleInput3Color] = useState("#eee");

    const  onFormSimpleInput3ColorChange = (e) =>{
        setTimeout(()=>{
            setFormSimpleInput3Color(e.target.value);
        },1000);
    }

    const [formSimpleButtonText, setFormSimpleButtonText] = useState("submit");

    const  onFormSimpleButtonTextChange = (e) =>{
        setFormSimpleButtonText(e.target.value);
    }

    const [formSimpleButtonColor, setFormSimpleButtonColor] = useState("#eee");

    const  onFormSimpleButtonColorChange = (e) =>{
        setTimeout(()=>{
            setFormSimpleButtonColor(e.target.value);
        },1000);
    }

    const [jsx, setJsx] = useState("");

    const [css, setCss] = useState("");

    useEffect(()=>{
        setJsx(`import React from "react";

import "./form-simple.css";
        
const FormSimple = () =>{
    return(
        <div className="form-simple">
            <div className="form-simple-title">
                ${formSimpleTitleText}
            </div>
            <div className="form-simple-subtitle">
                ${formSimpleSubTitleText}
            </div>
            <div className="form-simple-input-container form-simple-ic1">
                <input id="form-simple-input1" className="form-simple-input" type="text" placeholder="${formSimpleInput1Text}" />
            </div>
            <div className="form-simple-input-container form-simple-ic2">
                <input id="form-simple-input2" className="form-simple-input" type="text" placeholder="${formSimpleInput2Text}" />
                <div className="form-simple-cut"></div>
            </div>
            <div className="form-simple-input-container form-simple-ic2">
                <input id="form-simple-input3" className="form-simple-input" type="email" placeholder="${formSimpleInput3Text}" />
            </div>
            <button type="text" className="form-simple-submit">
                ${formSimpleButtonText}
            </button>
        </div>    
    );
}

export default FormSimple;`);},[formSimpleTitleText,formSimpleSubTitleText,formSimpleInput1Text,formSimpleInput2Text,formSimpleInput3Text,formSimpleButtonText]);
    
    useEffect(()=>{
        setCss(`.form-simple{
    background-color: ${formSimpleBackgroundColor};
    border-radius: 1.25rem;
    box-sizing: border-box;
    height: 31.25rem;
    padding: 1.25rem;
    max-width: 20rem;
    margin-left:30%;
}

#form-simple-input1{
    color:${formSimpleInput1Color};
}

#form-simple-input2{
    color:${formSimpleInput2Color};
}

#form-simple-input3{
    color:${formSimpleInput3Color};
}

.form-simple-title {
    color: ${formSimpleTitleColor};
    font-family: sans-serif;
    font-size: 2.25rem;
    font-weight: 600;
    margin-top: 1.875rem;
}

.form-simple-subtitle {
    color: ${formSimpleSubTitleColor};
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.625rem;
}

.form-simple-input-container {
    height: 3.125rem;
    position: relative;
    width: 100%;
}

.form-simple-ic1 {
    margin-top: 2.5rem;
}

.form-simple-ic2 {
    margin-top: 1.875rem;
}

.form-simple-input {
    background-color: ${formSimpleFormBackgroundColor};
    border-radius: 0.75rem;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    font-size: 1.125rem;
    height: 100%;
    outline: 0;
    padding: 0.25rem 1.25rem 0;
    width: 100%;
}

.placeholder {
    color: #65657b;
    font-family: sans-serif;
    left: 1.25rem;
    line-height: 0.875rem;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 1.25rem;
}


.form-simple-submit {
    background-color: ${formSimpleButtonBackgroundColor};
    border-radius: 0.75rem;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    cursor: pointer;
    font-size: 1.125rem;
    height: 3.125rem;
    margin-top: 2.375rem;
    outline: 0;
    text-align: center;
    width: 100%;
}

.form-simple-submit:active {
    background-color: #06b;
}`);
    },[formSimpleBackgroundColor,formSimpleFormBackgroundColor,formSimpleButtonBackgroundColor,formSimpleTitleColor,formSimpleSubTitleColor,formSimpleInput1Color,formSimpleInput2Color,formSimpleInput3Color]);

    return(
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="title-pane">
                            <p className="title-text">FORM-SIMPLE GENERATOR</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="accordian-simple-preview-pane">
                            <div className="accordian-simple-inner-container form-simple-preview-padding">
                                <div className="form-simple" style={{backgroundColor:formSimpleBackgroundColor}}>
                                    <div style={{color:formSimpleTitleColor}} className="form-simple-title">
                                        {formSimpleTitleText}
                                    </div>
                                    <div style={{color:formSimpleSubTitleColor}} className="form-simple-subtitle">
                                        {formSimpleSubTitleText}
                                    </div>
                                    <div className="form-simple-input-container form-simple-ic1">
                                        <input style={{backgroundColor:formSimpleFormBackgroundColor,color:formSimpleInput1Color}} id="form-simple-firstname" className="form-simple-input" type="text" placeholder={formSimpleInput1Text} />
                                    </div>
                                    <div className="form-simple-input-container form-simple-ic2">
                                        <input style={{backgroundColor:formSimpleFormBackgroundColor,color:formSimpleInput2Color}} id="form-simple-lastname" className="form-simple-input" type="text" placeholder={formSimpleInput2Text} />
                                        <div className="form-simple-cut"></div>
                                    </div>
                                    <div className="form-simple-input-container form-simple-ic2">
                                        <input style={{backgroundColor:formSimpleFormBackgroundColor,color:formSimpleInput3Color}} id="form-simple-email" className="form-simple-input" type="email" placeholder={formSimpleInput3Text} />
                                    </div>
                                    <button type="text" style={{backgroundColor:formSimpleButtonBackgroundColor,color:formSimpleButtonColor}}className="form-simple-submit">
                                        {formSimpleButtonText}
                                    </button>
                                </div>  {/*Form Simple end */}
                            </div> {/*Preview Pane end */}
                        </div>
                    </Col>
                </Row>
                <Form className="editor-container border border-info">
                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Background Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onFormSimpleBackgroundColor(e)}}
                                    defaultValue={formSimpleBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Input Form Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onFormSimpleFormBackgroundColor(e)}}
                                    defaultValue={formSimpleFormBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                        <Form.Label className="label-text" column sm="2">
                            Button Color:
                        </Form.Label>
                        <Col sm="2">
                            <InputGroup>
                                <span className="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onFormSimpleButtonBackgroundColor(e)}}
                                    defaultValue={formSimpleButtonBackgroundColor}
                                /></span>
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Title Text:
                        </Form.Label>
                        <Col sm="4">
                        <InputGroup>
                            <Form.Control defaultValue={formSimpleTitleText} onChange={(e)=>{
                                onFormSimpleTitleText(e);
                            }} />
                            <span class="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{onFormSimpleTitleColorChange(e)}}
                                defaultValue={formSimpleTitleColor}
                            /></span>
                        </InputGroup>
                    </Col>

                    <Form.Label className="label-text" column sm="2">
                            Sub Title Text:
                        </Form.Label>
                        <Col sm="4">
                        <InputGroup>
                            <Form.Control defaultValue={formSimpleSubTitleText} onChange={(e)=>{
                                onFormSimpleSubTitleText(e);
                            }} />
                            <span class="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{onFormSimpleSubTitleColorChange(e)}}
                                defaultValue={formSimpleSubTitleColor}
                            /></span>
                        </InputGroup>
                    </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Input 1:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <Form.Control defaultValue={formSimpleInput1Text} onChange={(e)=>{
                                    onFormSimpleInput1TextChange(e);
                                }}/>
                                <span class="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onFormSimpleInput1ColorChange(e)}}
                                    defaultValue={formSimpleInput1Color}
                                /></span>
                            </InputGroup>
                        </Col>

                        <Form.Label className="label-text" column sm="2">
                            Input 2:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <Form.Control defaultValue={formSimpleInput2Text} onChange={(e)=>{
                                    onFormSimpleInput2TextChange(e);
                                }}/>
                                <span class="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onFormSimpleInput2ColorChange(e)}}
                                    defaultValue={formSimpleInput2Color}
                                /></span>
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label className="label-text" column sm="2">
                            Input 3:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <Form.Control defaultValue={formSimpleInput3Text} onChange={(e)=>{
                                    onFormSimpleInput3TextChange(e);
                                }}/>
                                <span class="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onFormSimpleInput3ColorChange(e)}}
                                    defaultValue={formSimpleInput3Color}
                                /></span>
                            </InputGroup>
                        </Col>

                        <Form.Label className="label-text" column sm="2">
                            Button:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <Form.Control defaultValue={formSimpleButtonText} onChange={(e)=>{
                                    onFormSimpleButtonTextChange(e);
                                }}/>
                                <span class="input-group-addon" ><Form.Control
                                    className="square"
                                    type="color"
                                    onChange={(e)=>{onFormSimpleButtonColorChange(e)}}
                                    defaultValue={formSimpleButtonColor}
                                /></span>
                            </InputGroup>
                        </Col>

                    </Form.Group>
                </Form>
                <Row>
                <Col>
                    <div  className="editor-container border border-info">
       
                        <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                            <Tab eventKey="jsx" title="JSX">
                                <ControlledEditor
                                    key="jsx"
                                    value={jsx}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"jsx",
                                        readOnly:true
                                    }}
                                /> 
                            </Tab>
                            <Tab eventKey="css" title="CSS">
                                <ControlledEditor
                                   key="css"
                                   value={css}
                                   options={{
                                       lineWrapping:true,
                                       lint:true,
                                       lineNumbers:true,
                                       theme:"dracula",
                                       mode:"css",
                                       readOnly:true
                                   }}
                               />
                            </Tab>    
                        </Tabs>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="border border-info how-to-container">
                        <span className="how-to-title">How to Use</span>
                        <ol className="">
                            <li>
                                Create a jsx file called <strong>form-simple.jsx</strong> and a css file called <strong>form-simple.css</strong> in your react-app.
                            </li>
                            <li>
                                Copy and paste the code above in to a jsx file and a css file.
                            </li>
                            <li>
                                Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                            </li>
                            <li>
                                Add the line: import FormSimple from "./form-simple.jsx"; to App.js or any jsx file
                            </li>
                            <li>
                                Import {"<FormSimple/>"} in to your App.js file
                            </li>
                            <li>
                                Run your react app
                            </li>
                        </ol>
                    </div>
                </Col>
            </Row>                    
            </Container>
        </div>
    );
}

export default FormSimple;

