import React from "react";
import "./loader.styles.scss";
const Loader = () => {
	
	return (
		<div className="center">
		<div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
		</div>
	)
}

export default Loader;