import React, {useState, useEffect} from "react";
import { Container, Row, Col, Form, InputGroup, Tabs, Tab } from 'react-bootstrap';

import "./accordian-simple.styles.scss";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/dracula.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/css/css";
import "codemirror/mode/jsx/jsx";
import { Controlled as ControlledEditor} from "react-codemirror2";


const AccordianSimple = ()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    const [tabKey,setTabKey] = useState("jsx");
    const [selected,setSelected] = useState(null);
    const [numberOfAccordianItems, setNumberOfAccordianItems]=useState(4); 

    const [textCategory1, setTextCategory1]=useState("Category 1");
    const [colorCategory1, setColorCategory1]=useState("#0096c7");
    const [fontWeightCategory1, setFontWeightCategory1]=useState(600);
    const [fontFamilyCategory1, setFontFamilyCategory1]=useState("sans-serif");
    const [textCategory2,setTextCategory2] = useState("Category 2");
    const [textCategory3,setTextCategory3] = useState("Category 3");
    const [textCategory4,setTextCategory4] = useState("Category 4");
    const [textCategory5,setTextCategory5] = useState("Category 5");
    const [textCategory6,setTextCategory6] = useState("Category 6");
    const [textCategory7,setTextCategory7] = useState("Category 7");
    const [textCategory8,setTextCategory8] = useState("Category 8");
    const [textCategory9,setTextCategory9] = useState("Category 9");
    const [textCategory10,setTextCategory10] = useState("Category 10");

    const [textContent1,setTextContent1] = useState("Content 1");
    const [textContent2,setTextContent2] = useState("Content 2");
    const [textContent3,setTextContent3] = useState("Content 3");
    const [textContent4,setTextContent4] = useState("Content 4");
    const [textContent5,setTextContent5] = useState("Content 5");
    const [textContent6,setTextContent6] = useState("Content 6");
    const [textContent7,setTextContent7] = useState("Content 7");
    const [textContent8,setTextContent8] = useState("Content 8");
    const [textContent9,setTextContent9] = useState("Content 9");
    const [textContent10,setTextContent10] = useState("Content 10");

    const [colorContent,setColorContent] = useState("#023e8a");
    const [fontWeightContent, setFontWeightContent]=useState(600);
    const [fontFamilyContent, setFontFamilyContent]=useState("sans-serif");
    
    const [backgroundColor,setBackgroundColor] = useState("#caf0f8");

    const onBackgroundColorChange = (e)=>{
        setTimeout(()=>{
            setBackgroundColor(e.target.value)
        },1000);
    }

    const toggle = (i)=>{
        if( selected === i){
            setSelected(null);
            return;
        }

        setSelected(i);
        return;
    }

    const onNumberOfAccordianItemsChange = (e)=>{
        setNumberOfAccordianItems(e.target.value);
    }
    
    const onCategoryText1Change = (e) =>{
        setTextCategory1(e.target.value);
    }

    const onCategoryText2Change = (e) =>{
        setTextCategory2(e.target.value);
    }

    const onCategoryText3Change = (e) =>{
        setTextCategory3(e.target.value);
    }

    const onCategoryText4Change = (e) =>{
        setTextCategory4(e.target.value);
    }

    const onCategoryText5Change = (e) =>{
        setTextCategory5(e.target.value);
    }

    const onCategoryText6Change = (e) =>{
        setTextCategory6(e.target.value);
    }

    const onCategoryText7Change = (e) =>{
        setTextCategory7(e.target.value);
    }

    const onCategoryText8Change = (e) =>{
        setTextCategory8(e.target.value);
    }

    const onCategoryText9Change = (e) =>{
        setTextCategory9(e.target.value);
    }

    const onCategoryText10Change = (e) =>{
        setTextCategory10(e.target.value);
    }

    const onContentText1Change = (e) =>{
        setTextContent1(e.target.value);
    }

    const onContentText2Change = (e) =>{
        setTextContent2(e.target.value);
    }

    const onContentText3Change = (e) =>{
        setTextContent3(e.target.value);
    }

    const onContentText4Change = (e) =>{
        setTextContent4(e.target.value);
    }

    const onContentText5Change = (e) =>{
        setTextContent5(e.target.value);
    }

    const onContentText6Change = (e) =>{
        setTextContent6(e.target.value);
    }

    const onContentText7Change = (e) =>{
        setTextContent7(e.target.value);
    }

    const onContentText8Change = (e) =>{
        setTextContent8(e.target.value);
    }

    const onContentText9Change = (e) =>{
        setTextContent9(e.target.value);
    }

    const onContentText10Change = (e) =>{
        setTextContent10(e.target.value);
    }

    const onColorCategory1Change = (e)=>{
        setTimeout(()=>{
            setColorCategory1(e.target.value);
        },1000);
    }

    const onColorContentChange = (e)=>{
        setTimeout(()=>{
            setColorContent(e.target.value);
        },1000);
    }

    const onFontWeightCategory1Change = (e)=>{
        switch(e.target.value){
            case "Normal":
                setFontWeightCategory1(400);
                break;
            case "Bold":
                setFontWeightCategory1(600);
                break;
            case "Extra-Bold":
                setFontWeightCategory1(800);
                break;
            default:
                break ;
                
        }
    }

    const onFontWeightContentChange = (e)=>{
        switch(e.target.value){
            case "Normal":
                setFontWeightContent(400);
                break;
            case "Bold":
                setFontWeightContent(600);
                break;
            case "Extra-Bold":
                setFontWeightContent(800);
                break;
            default:
                break ;
                
        }
    }

    const onFontFamilyCategory1Change = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setFontFamilyCategory1("Arial Narrow");
                return;
            case "Arial Round":
                setFontFamilyCategory1("Arial Round");
                return;
            case "Calibri":
                setFontFamilyCategory1("Calibri");
                return;
            case "Cambria":
                setFontFamilyCategory1("Cambria");
                return;
            case "Courier":
                setFontFamilyCategory1("Courier");
                return;
            case "Franklin Gothic Medium":
                setFontFamilyCategory1("Franklin Gothic Medium");
                return;
            case "Garamond":
                setFontFamilyCategory1("Garamond");
                return;
            case "Geneva":
                setFontFamilyCategory1("Geneva");
                return;
            case "Georgia":
                setFontFamilyCategory1("Georgia");
                return;
            case "Gill Sans":
                setFontFamilyCategory1("Gill Sans");
                return;
            case "Impact":
                setFontFamilyCategory1("Impact");
                return;
            case "Lucida Sans":
                setFontFamilyCategory1("Lucida Sans");
                return;
            case "Sans Serif":
                setFontFamilyCategory1("sans-serif");
                return;
            case "Segoe UI":
                setFontFamilyCategory1("Segoe UI");
                return;
            case "Times New Roman":
                setFontFamilyCategory1("Times New Roman");
                return;
            case "Trebuchet MS":
                setFontFamilyCategory1("Trebuchet MS");
                return;
            case "Verdana":
                setFontFamilyCategory1("Verdana");
                return;
            default:
                return;    
        }
    }

    const onFontFamilyContentChange = (e) =>{
        switch(e.target.value){
            case "Arial Narrow":
                setFontFamilyContent("Arial Narrow");
                return;
            case "Arial Round":
                setFontFamilyContent("Arial Round");
                return;
            case "Calibri":
                setFontFamilyContent("Calibri");
                return;
            case "Cambria":
                setFontFamilyContent("Cambria");
                return;
            case "Courier":
                setFontFamilyContent("Courier");
                return;
            case "Franklin Gothic Medium":
                setFontFamilyContent("Franklin Gothic Medium");
                return;
            case "Garamond":
                setFontFamilyContent("Garamond");
                return;
            case "Geneva":
                setFontFamilyContent("Geneva");
                return;
            case "Georgia":
                setFontFamilyContent("Georgia");
                return;
            case "Gill Sans":
                setFontFamilyContent("Gill Sans");
                return;
            case "Impact":
                setFontFamilyContent("Impact");
                return;
            case "Lucida Sans":
                setFontFamilyContent("Lucida Sans");
                return;
            case "Sans Serif":
                setFontFamilyContent("sans-serif");
                return;
            case "Segoe UI":
                setFontFamilyContent("Segoe UI");
                return;
            case "Times New Roman":
                setFontFamilyContent("Times New Roman");
                return;
            case "Trebuchet MS":
                setFontFamilyContent("Trebuchet MS");
                return;
            case "Verdana":
                setFontFamilyContent("Verdana");
                return;
            default:
                return;    
        }
    }

   

    

    const [jsx, setJsx] = useState("");

    const [css, setCss] = useState("");
    
    useEffect(()=>{
        setJsx(`import React, {useState} from "react";
import "./accordian-simple.css";

const AccordianSimple = ()=> {

    const [selected,setSelected] = useState(null);

    const toggle = (i)=>{
        if( selected === i){
            setSelected(null);
            return;
        }

        setSelected(i);
        return;
    }
    
    let data= [
        {
            category:"${textCategory1}",
            content:"${textContent1}"
        },
        {
            category:"${textCategory2}",
            content:"${textContent2}"
        },
        {
            category:"${textCategory3}",
            content:"${textContent3}"
        },
        {
            category:"${textCategory4}",
            content:"${textContent4}"
        },
        {
            category:"${textCategory5}",
            content:"${textContent5}"
        },
        {
            category:"${textCategory6}",
            content:"${textContent6}"
        },
        {
            category:"${textCategory7}",
            content:"${textContent7}"
        },
        {
            category:"${textCategory8}",
            content:"${textContent8}"
        },
        {
            category:"${textCategory9}",
            content:"${textContent9}"
        },
        {
            category:"${textCategory10}",
            content:"${textContent10}"
        }
    ];
    return (
        <div className="wrapper">
            <div className="container-accordian">
            {
                data.map((item,i)=>{
                    return <div key={i} className="accordian-items" >
                        <div className="category" onClick={() =>toggle(i)}>
                            {item.category}
                            <span>{selected === i ? "-" : "+"}</span>
                        </div>
                        <div className={selected === i ? "content-show" : "content"}>
                            {item.content}
                        </div>
                    </div>;
                })
            }
            </div>
        </div>
    );
}

export default AccordianSimple;`);

    },[textCategory1,textCategory2,textCategory3,textCategory4,textCategory5,textCategory6,textCategory7,textCategory8,textCategory9,textCategory10,textContent1,textContent2,textContent3,textContent4,textContent5,textContent6,textContent7,textContent8,textContent9,textContent10]);

    useEffect(()=>{

        setCss(`.accordian-simple-inner-container{
    background-color:white;
    margin: 0 auto;
    padding:2%;
}

.accordian-simple-wrapper{
    display:flex;
    margin-right:15%;
    margin-left:15%;
    justify-content:center;
    align-items: center;
}
.acccordian-simple-container-accordian{

    width:31.25rem;

}

.accordian-items{
    background: ${backgroundColor};
    margin-bottom:0.313rem;
    padding:0.625rem 1.25rem;
}

.category{
    font-size:1.5rem;
    font-weight:700;
    font-family: 'Roboto', sans-serif;
    color:#0096c7;
    display:flex;
    justify-content: space-between;
    align-items:center;
    cursor:pointer;
}

.content{
    overflow-wrap: anywhere;
    font-size:1rem;
    font-family: 'Roboto', sans-serif;
    font-weight:400;
    max-height:0;
    overflow:hidden;
    color:#023e8a;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}

.content-show{
    overflow-wrap: anywhere;
    font-size:1rem;
    font-family: 'Roboto', sans-serif;
    font-weight:400;
    color:#023e8a;
    overflow:auto;
    max-height:9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}`);
    },[backgroundColor]);
    
    let data= [
        {
            category:textCategory1,
            content:textContent1
        },
        {
            category:textCategory2,
            content:textContent2
        },
        {
            category:textCategory3,
            content:textContent3
        },
        {
            category:textCategory4,
            content:textContent4
        },
        {
            category:textCategory5,
            content:textContent5
        },
        {
            category:textCategory6,
            content:textContent6
        },
        {
            category:textCategory7,
            content:textContent7
        },
        {
            category:textCategory8,
            content:textContent8
        },
        {
            category:textCategory9,
            content:textContent9
        },
        {
            category:textCategory10,
            content:textContent10
        }
    ];

 return (
     <div>
         <Container>
            <Row>
                <Col>
                    <div className="title-pane">
                        
                        <p className="title-text">SIMPLE ACCORDIAN GENERATOR</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="accordian-simple-preview-pane">
                        <div className="accordian-simple-inner-container">
                            <div className="accordian-simple-wrapper">
                                <div  className="acccordian-simple-container-accordian">
                                    {
                                        data.map((item,i)=>{
                                            return <div key={i} style={i+1 > numberOfAccordianItems ? {display:"none"}:{backgroundColor:backgroundColor}} className="accordian-items">
                                                <div style={{fontWeight:fontWeightCategory1,fontFamily:fontFamilyCategory1,color:colorCategory1}} className="category" onClick={() =>toggle(i)}>
                                                    {item.category}
                                                    <span>{selected === i ? "-" : "+"}</span>
                                                </div>
                                                <div style={{fontWeight:fontWeightContent,fontFamily:fontFamilyContent,color:colorContent}} className={selected === i ? "content-show" : "content"}>
                                                    {item.content}
                                                </div>
                                            </div>;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
           
           <Form className="editor-container border border-info">

                <Form.Group as={Row}>
                    <Form.Label className="label-text" column sm="2">
                        # of Items:
                    </Form.Label>

                    <Col sm="2">
                        <Form.Control  defaultValue="4" onChange={(e)=>{
                                onNumberOfAccordianItemsChange(e);
                            }} as="select">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                        </Form.Control>
                        
                    </Col>

                    <Col sm="4">
                        <InputGroup>
                            <Form.Control readOnly={true} defaultValue="Background Color" />
                            <span className="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{onBackgroundColorChange(e)}}
                                defaultValue={backgroundColor}
                            /></span>
                        </InputGroup>
                    </Col>
                    
                </Form.Group>

                <Form.Group as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        Category Text:
                    </Form.Label>
                    
                    <Col sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory1} onChange={(e)=>{
                                onCategoryText1Change(e);
                            }} />
                        </InputGroup>
                    </Col>

                    <Col style={ numberOfAccordianItems < 2  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control  defaultValue={textCategory2} onChange={(e)=>{
                                onCategoryText2Change(e);
                            }} />
                        </InputGroup>
                    </Col>

                    <Col style={ numberOfAccordianItems < 3  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory3} onChange={(e)=>{
                                onCategoryText3Change(e);
                            }} />
                        </InputGroup>
                    </Col>

                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 4  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col style={ numberOfAccordianItems < 4  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory4} onChange={(e)=>{
                                onCategoryText4Change(e);
                            }} />
                        </InputGroup>
                    </Col>
                    <Col style={ numberOfAccordianItems < 5  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory5} onChange={(e)=>{
                                onCategoryText5Change(e);
                            }} />
                        </InputGroup>
                    </Col>
                    <Col style={ numberOfAccordianItems < 6  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory6} onChange={(e)=>{
                                onCategoryText6Change(e);
                            }} />
                        </InputGroup>
                    </Col>

                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 7  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col style={ numberOfAccordianItems < 7  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory7} onChange={(e)=>{
                                onCategoryText7Change(e);
                            }} />
                        </InputGroup>
                    </Col>
                    <Col style={ numberOfAccordianItems < 8  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory8} onChange={(e)=>{
                                onCategoryText8Change(e);
                            }} />
                        </InputGroup>
                    </Col>
                    <Col style={ numberOfAccordianItems < 9  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory9} onChange={(e)=>{
                                onCategoryText9Change(e);
                            }} />
                        </InputGroup>
                    </Col>

                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 10  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col style={ numberOfAccordianItems < 10  ? {display:"none"}:{}} sm="3">
                        <InputGroup>
                            <Form.Control defaultValue={textCategory10} onChange={(e)=>{
                                onCategoryText10Change(e);
                            }} />
                        </InputGroup>
                    </Col>
                </Form.Group>

                <Form.Group  as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        Category Attributes:
                    </Form.Label>

                    <Col sm="4">
                        <InputGroup>
                            <Form.Control readOnly={true} defaultValue="Font Color" />
                            <span className="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{onColorCategory1Change(e)}}
                                defaultValue={colorCategory1}
                            /></span>
                        </InputGroup>
                    </Col>

                    <Col sm="3">
                        <Form.Control defaultValue="Bold" onChange={(e)=>{onFontWeightCategory1Change(e)}} as="select">
                            <option>Normal</option>
                            <option>Bold</option>
                            <option>Extra-Bold</option>
                        </Form.Control>
                        
                    </Col>
                    <Col sm="3">
                        <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                onFontFamilyCategory1Change(e);
                            }} as="select">
                            <option>Arial Narrow</option>
                            <option>Arial Round</option>
                            <option>Calibri</option>
                            <option>Cambria</option>
                            <option>Courier</option>
                            <option>Franklin Gothic Medium</option>
                            <option>Garamond</option>
                            <option>Geneva</option>
                            <option>Georgia</option>
                            <option>Gill Sans</option>
                            <option>Impact</option>
                            <option>Lucida Sans</option>
                            <option>Sans Serif</option>
                            <option>Segoe UI</option>
                            <option>Times New Roman</option>
                            <option>Trebuchet MS</option>
                            <option>Verdana</option>
                        </Form.Control>
                        
                    </Col>                 
                </Form.Group>

                {/*content section */}
                <Form.Group as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        Content Text:
                    </Form.Label>
                    
                    <Col sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent1} onChange={(e)=>{
                                onContentText1Change(e);
                            }} />
                        </InputGroup>
                    </Col>
                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 2  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>
                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent2} onChange={(e)=>{
                                onContentText2Change(e);
                            }} />
                        </InputGroup>
                    </Col>
                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 3  ? {display:"none"}:{}} as={Row} className="">
                    <Col  sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent3} onChange={(e)=>{
                                onContentText3Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 4  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent4} onChange={(e)=>{
                                onContentText4Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group>        
                
                <Form.Group style={ numberOfAccordianItems < 5  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent5} onChange={(e)=>{
                                onContentText5Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 6  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent6} onChange={(e)=>{
                                onContentText6Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group> 

                <Form.Group style={ numberOfAccordianItems < 7  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent7} onChange={(e)=>{
                                onContentText7Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 8  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent8} onChange={(e)=>{
                                onContentText8Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 9  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent9} onChange={(e)=>{
                                onContentText9Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group>

                <Form.Group style={ numberOfAccordianItems < 10  ? {display:"none"}:{}} as={Row} className="">
                    <Col sm="2">

                    </Col>

                    <Col  sm="10">
                        <InputGroup>
                            <Form.Control defaultValue={textContent10} onChange={(e)=>{
                                onContentText10Change(e);
                            }} />
                        </InputGroup>
                    </Col>  
                </Form.Group>

                <Form.Group  as={Row} className="">
                    <Form.Label className="label-text" column sm="2">
                        Content Attributes:
                    </Form.Label>

                    <Col sm="4">
                        <InputGroup>
                            <Form.Control readOnly={true} defaultValue="Font Color" />
                            <span className="input-group-addon" ><Form.Control
                                className="square"
                                type="color"
                                onChange={(e)=>{onColorContentChange(e)}}
                                defaultValue={colorContent}
                            /></span>
                        </InputGroup>
                    </Col>

                    <Col sm="3">
                        <Form.Control defaultValue="Bold" onChange={(e)=>{onFontWeightContentChange(e)}} as="select">
                            <option>Normal</option>
                            <option>Bold</option>
                            <option>Extra-Bold</option>
                        </Form.Control>
                        
                    </Col>
                    <Col sm="3">
                        <Form.Control defaultValue="Sans Serif" onChange={(e)=>{
                                onFontFamilyContentChange(e);
                            }} as="select">
                            <option>Arial Narrow</option>
                            <option>Arial Round</option>
                            <option>Calibri</option>
                            <option>Cambria</option>
                            <option>Courier</option>
                            <option>Franklin Gothic Medium</option>
                            <option>Garamond</option>
                            <option>Geneva</option>
                            <option>Georgia</option>
                            <option>Gill Sans</option>
                            <option>Impact</option>
                            <option>Lucida Sans</option>
                            <option>Sans Serif</option>
                            <option>Segoe UI</option>
                            <option>Times New Roman</option>
                            <option>Trebuchet MS</option>
                            <option>Verdana</option>
                        </Form.Control>
                        
                    </Col>                 
                </Form.Group>
                
            </Form>

            <Row>
                <Col>
                    <div  className="editor-container border border-info">
       
                        <Tabs mountOnEnter  activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                            <Tab eventKey="jsx" title="JSX">
                                <ControlledEditor
                                    key="jsx"
                                    value={jsx}
                                    options={{
                                        lineWrapping:true,
                                        lint:true,
                                        lineNumbers:true,
                                        theme:"dracula",
                                        mode:"jsx",
                                        readOnly:true
                                    }}
                                /> 
                            </Tab>
                            <Tab eventKey="css" title="CSS">
                                <ControlledEditor
                                   key="css"
                                   value={css}
                                   options={{
                                       lineWrapping:true,
                                       lint:true,
                                       lineNumbers:true,
                                       theme:"dracula",
                                       mode:"css",
                                       readOnly:true
                                   }}
                               />
                            </Tab>    
                        </Tabs>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="border border-info how-to-container">
                        <span className="how-to-title">How to Use</span>
                        <ol className="">
                            <li>
                                Create a jsx file called <strong>accordian-simple.jsx</strong> and a css file called <strong>accordian-simple.css</strong> in your react-app.
                            </li>
                            <li>
                                Copy and paste the code above in to a jsx file and a css file.
                            </li>
                            <li>
                                Put them in the same folder. If placed in a seperate folder make sure to change the import path.
                            </li>
                            <li>
                                Add the line: import AccordianSimple from "./accordian-simple.jsx"; to App.js or any jsx file
                            </li>
                            <li>
                                Import {"<AccordianSimple/>"} in to your App.js file
                            </li>
                            <li>
                                Run your react app
                            </li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </Container>
     </div>
 )
}

export default AccordianSimple;